<template>
  <div class="tax-identification">
    <div
      v-if="currentLocationKey === 'us'"
      class="us-tax-guidestar"
    >
      <div>
        <img
          class="guidestar-platinum-seal"
          src="https://assets.survivalinternational.org/viru/logos/guidestar-platinum-seal.png"
          alt="GuideStar Platinum Seal"
        >
      </div>
      <div>
        <span>{{ $t('donationForm.details.guidestar.tax_id') }}</span>
      </div>
    </div>
    <ItTaxInformation v-else-if="showItalyTaxID" />
    <EsTaxInformation v-else-if="showSpainTaxID" />
  </div>
</template>

<script>
import EsTaxInformation from '@/components/donation/tax/EsTaxInformation'
import ItTaxInformation from '@/components/donation/tax/ItTaxInformation'
import { useDonationStore } from '@/stores/donation'
import { useL10nStore } from '@/stores/l10n'

export default {
  name: 'TaxIdentification',
  components: { EsTaxInformation, ItTaxInformation },
  setup () {
    const donationStore = useDonationStore()
    const l10nStore = useL10nStore()
    return { donationStore, l10nStore }
  },
  computed: {
    currentCountry () { return this.donationStore.country },
    currentLocationKey () { return this.l10nStore.current.key },
    showSpainTaxID () { return (this.currentLocationKey === 'es') && (this.currentCountry === 'ES') },
    showItalyTaxID () { return (this.currentLocationKey === 'it') },
  }
}
</script>

<style lang="scss" scoped>
  .us-tax-guidestar {
    margin-top: 1em;
    div {
      img.guidestar-platinum-seal {
        width: 6rem;

        @include on-non-mobile(){
          width: 6rem;
        }
      }
      span {
        font-family: 'Stag-medium';
        color: white;
      }
    }
  }
</style>
