<template>
  <transition
    name="slide"
    @before-leave="beforeLeave"
    @leave="leave"
    @before-enter="beforeEnter"
    @enter="enter"
  >
    <slot />
  </transition>
</template>

<script>
import { Tween, Easing } from '@tweenjs/tween.js'

export default {
  name: 'TransitionSlide',
  props: {
    time: { type: Number, default: 500 }
  },
  methods: {
    beforeEnter (el) {
      el.style.setProperty('max-height', 'none')
      el.style.setProperty('min-height', 0)
      el.style.setProperty('overflow', 'hidden')
    },
    beforeLeave (el) {
      el.style.setProperty('height', el.clientHeight)
      el.style.setProperty('max-height', 'none')
      el.style.setProperty('min-height', 0)
      el.style.setProperty('overflow', 'hidden')
    },

    leave (el, done) {
      const currentStyles = getComputedStyle(el)
      new Tween({ height: el.clientHeight, opacity: currentStyles.opacity })
        .to({ height: 0, opacity: 0 }, this.time)
        .easing(Easing.Quadratic.InOut)
        .onUpdate((val) => {
          el.style.setProperty('height', `${val.height}px`)
          el.style.setProperty('opacity', val.opacity)
        })
        .onComplete(done)
        .start()
    },
    enter (el, done) {
      this.$nextTick(() => {
        const currentStyles = getComputedStyle(el)
        new Tween({ height: 0, opacity: 0 })
          .to({ height: el.clientHeight, opacity: currentStyles.opacity }, this.time)
          .easing(Easing.Quadratic.InOut)
          .onUpdate((val) => {
            el.style.setProperty('height', `${val.height}px`)
            el.style.setProperty('opacity', val.opacity)
          })
          .onComplete(done)
          .start()
      })
    }
  }
}
</script>
