<template>
  <div class="country-wrapper">
    <h3
      v-if="country === 'none'"
      class="country-help"
    >
      Please select your country
    </h3>
    <label :class="{ error: v$.$error }">{{ $t('location.label') }}</label>
    <SelectField
      class="country"
      :list="$tm('countries')"
      v-model="country"
      :validation="v$.country"
    />
  </div>
</template>

<script>
import SelectField from '@/components/form/SelectField'
import countrycodeToOffice from '@/locations/countryCodeToOffice'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useRootStore } from '@/stores/root'
import { useDonationStore } from '@/stores/donation'

export default {
  name: 'CountryLocationSelect',
  components: {
    SelectField
  },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    return { rootStore, donationStore, v$: useVuelidate() }
  },
  methods: {
    updateOfficeFromCountryCode (countryCode) {
      const officeLocale = countrycodeToOffice[countryCode]
      this.$l10n.changeLocation(officeLocale)
    }
  },
  computed: {
    country: {
      get () { return this.donationStore.country },
      set (newValue) { this.donationStore.setCountry(newValue) }
    }
  },
  validations () {
    return {
      country: {
        required,
        country: value => value !== 'none'
      }
    }
  },
  watch: {
    country () {
      this.updateOfficeFromCountryCode(this.country)
    }
  }
}
</script>

<style lang="scss" scoped>
  .country-wrapper {
    margin-bottom: 1rem;
    label {
      line-height: 40px;
      font-weight: bold;
    }
    label.error {
      color: $color-validation-fail;
    }
    div.form-field.country {
      margin-top: 0.1rem;
      padding-top: 0.1rem;
    }

    h3.country-help {
      color: white;
      font-family: "Stag-medium";
      margin: 0;
    }
  }
</style>
