<template>
  <div class="with-navigation">
    <MainNav />
    <router-view />
    <MainFooter />
  </div>
</template>

<script>
  import MainNav from './MainNav'
  import MainFooter from './MainFooter'

  export default {
    name: 'WithNavigation',
    components: { MainNav, MainFooter }
  }
</script>
<style lang="scss" scoped>
  .with-navigation{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh + 110px);

    > div{
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
    }
  }
</style>
