// Taken directly from MDN:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detectinglokalStorage
// Should account for private browsing & storage quota exceeded.

export default function storageAvailable (lokalStorage) {
  try {
    const x = '__storage_test__'
    lokalStorage.setItem(x, x)
    lokalStorage.removeItem(x)
    return true
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (lokalStorage && lokalStorage.length !== 0)
  }
}
