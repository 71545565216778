<template>
  <div class="gift-this-donation">
    <CheckBox
      :label="$t('giftDonation.checkLabel')"
      v-model="giftDonation"
    />
    <TransitionSlide>
      <div v-if="giftDonation">
        <h4>{{ $t('giftDonation.name.title') }}</h4>
        <FormField
          :label="$t('giftDonation.name.label')"
          v-model="giftData.name"
          :validation="v$.giftData.name"
        />
        <h4>{{ $t('giftDonation.email.title') }}</h4>
        <FormField
          :label="$t('giftDonation.email.label')"
          v-model="giftData.email"
          :validation="v$.giftData.email"
          type="email"
        />
        <small>{{ $t('giftDonation.email.note') }}</small>
        <FormField
          :label="$t('giftDonation.message.label')"
          v-model="giftData.message"
          :validation="v$.giftData.message"
          type="textarea"
        />
        <small>
          {{ $t('giftDonation.message.characters') }}
          {{ `${charsTaken}/400` }}
        </small>
      </div>
    </TransitionSlide>
  </div>
</template>

<script>
import CheckBox from '@/components/form/CheckBox'
import FormField from '@/components/form/FormField'
import TransitionSlide from '@/transitions/Slide'
import { requiredIf, email, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useDonationStore } from '@/stores/donation'
import { ref } from 'vue'

export default {
  name: 'GiftDonation',
  components: {
    CheckBox, FormField, TransitionSlide
  },
  setup () {
    const donationStore = useDonationStore()
    const giftDonation = ref(false)
    const giftData = ref({ name: null, email: null, message: '' })
    return { donationStore, v$: useVuelidate(), giftDonation, giftData }
  },
  validations () {
    return {
      giftData: {
        name: { required: requiredIf(function () { return this.giftDonation }) },
        email: { email },
        message: {
          message: maxLength(400)
        }
      }
    }
  },
  computed: {
    charsTaken () { return this.giftData.message.length },
    fieldsInvalid () { return this.v$.$invalid }
  },
  watch: {
    giftDonation: function (val) {
      if (val === false) {
        this.donationStore.setGiftDonation(null)
        this.giftData = { name: null, email: null, message: '' }
      }
    },
    'giftData.name': function (_val) {
      if (!this.fieldsInvalid && this.giftDonation) {
        this.donationStore.setGiftDonation(`name=${this.giftData.name};email=${this.giftData.email};message=${this.giftData.message}`)
      }
    },
    'giftData.email': function (_val) {
      if (!this.fieldsInvalid && this.giftDonation) {
        this.donationStore.setGiftDonation(`name=${this.giftData.name};email=${this.giftData.email};message=${this.giftData.message}`)
      }
    },
    'giftData.message': function (_val) {
      if (!this.fieldsInvalid && this.giftDonation) {
        this.donationStore.setGiftDonation(`name=${this.giftData.name};email=${this.giftData.email};message=${this.giftData.message}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .gift-this-donation {
    margin-top: 1rem;

    h4 {
      color: white;
      font-family: $titles-font-family-medium;
      margin-bottom: 0.1rem;
    }

    .form-check {
      padding-top: 0.5em;
    }
  }
</style>
