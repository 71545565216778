<template>
  <div class="form-check">
    <input
      type="checkbox"
      :id="id"
      v-model="val"
    >
    <label
      :for="id"
      v-if="label"
    >
      <span class="check" />
      <span class="text">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  name: 'CheckBox',
  props: {
    modelValue: { required: true, type: Boolean },
    label: { required: true, type: String }
  },
  emits: ['update:modelValue'],
  computed: {
    id () { return `element-${uuid()}` },
    val: {
      get () { return this.modelValue },
      set (newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  }
}
</script>
