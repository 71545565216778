<template>
  <section class="share">
    <div class="container contain">
      <div class="share">
        <h3>
          {{ $t('share.title') }}
        </h3>
        <div class="links">
          <template
            v-for="(key) in Object.keys($tm('share.socialMedia'))"
            :key="key"
          >
            <div :class="`${key}-share`">
              <a :href="$t(`share.socialMedia.${key}.url`)">
                <img
                  :aria-label="$t(`share.socialMedia.${key}.label`)"
                  :class="key"
                  :src="$t(`share.socialMedia.${key}.imagePath`)"
                  :alt="$t(`share.socialMedia.${key}.label`)"
                  :title="$t(`share.socialMedia.${key}.label`)"
                >
              </a>
            </div>
          </template>
          <div
            v-if="canCopy"
            class="add-to-clipboard"
          >
            <a
              @click="addToClipboard"
              :title="$t('share.canonicalUrl')"
            >
              <img
                :aria-label="$t('share.copyToClipboard.label')"
                :class="{ copied: copied }"
                :src="$t('share.copyToClipboard.imagePath')"
                :alt="$t('share.copyToClipboard.label')"
                :title="$t('share.copyToClipboard.label')"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from 'vue'

export default {
  name: 'SocialShare',
  setup () {
    const copied = ref(false)
    return { copied }
  },
  methods: {
    async addToClipboard () {
      this.copied = !this.copied
      await this.$clipboard.writeText(this.$t('share.canonicalUrl'))
    }
  },
  computed: {
    canCopy () {
      return !!this.$clipboard
    }
  }
}
</script>
<style lang="scss" scoped>
.share {
  width: 10rem;
  margin-left: auto;
  margin-right: auto;

  .links {
    display: flex;
    justify-content: space-between;
    div {
      flex: 0 1 4rem;
      img {
        width: 4rem;
        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }
  .add-to-clipboard {
    .copied {
      @include bounceIn();
    }
  }
}
</style>
