<template>
  <div
    class="form-field"
    :class="{ error: hasError && !active, light: theme === 'light' }"
  >
    <label
      :for="id"
      v-if="label"
      :type="type"
      :class="{ active: active || hasValue }"
    >
      {{ label }}
    </label>
    <input
      v-if="type === 'number'"
      @keypress="isNumber($event)"
      :id="id"
      v-model.number="val"
      :type="type"
      v-on="{focus, blur}"
      step="1.0"
      min="0"
    >
    <textarea
      v-else-if="type === 'textarea'"
      :id="id"
      v-model="val"
      :type="type"
      v-on="{focus, blur}"
      maxlength="400"
    />
    <input
      v-else
      :id="id"
      v-model="val"
      :type="type"
      v-on="{focus, blur}"
    >
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'

export default {
  name: 'FormField',
  setup () {
    const active = ref(false)
    return { active }
  },
  props: {
    modelValue: { required: true, type: [String, Number, null] },
    validation: { required: true, type: Object },
    type: {
      default: 'text',
      validator: (value) => {
        return ['text', 'textarea', 'email', 'password', 'number'].indexOf(value) !== -1
      }
    },
    label: { type: String, default: '' },
    theme: { type: String, default: '' }
  },
  emits: ['update:modelValue'],
  computed: {
    id () { return `element-${uuid()}` },
    hasError () { return this.validation && this.validation.$error },
    val: {
      get () { return this.modelValue },
      set (newValue) {
        this.validation && this.validation.$touch()
        this.$emit('update:modelValue', newValue)
      }
    },
    hasValue () { return this.modelValue && this.modelValue !== '' }
  },
  methods: {
    focus () { this.active = true },
    blur () {
      this.validation && this.validation.$touch()
      this.active = false
    },
    isNumber (event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault()
    }
  }
}
</script>
