import { defineStore } from 'pinia'
import { objectKeysCamelToSnakeCase } from '@/utilities/camelToSnakeCase'

export const useDonationStore = defineStore('donation', {
  namespaced: true,
  state: () => {
    return {
      reference: null,
      type: 'monthly',
      amount: null,
      forename: null,
      surname: null,
      email: null,
      phone: '',
      currency: null,
      street: null,
      zip: null,
      city: null,
      state: '',
      country: 'none',
      giftaid: false,
      giftDonation: null,
      newsletter: false,
      tax: { idType: 'none', idNumber: null, taxNumber: null },
      utms: {}
    }
  },
  getters: {
    currentDonation: (state) => {
      return {
        reference: state.reference,
        type: state.type,
        amount: state.amount,
        forename: state.forename,
        surname: state.surname,
        email: state.email,
        phone: state.phone,
        currency: state.currency,
        street: state.street,
        zip: state.zip,
        city: state.city,
        state: state.state,
        country: state.country,
        giftaid: state.giftaid,
        giftDonation: state.giftDonation,
        newsletter: state.newsletter,
        tax: state.tax,
        utms: state.utms
      }
    },
    donationParams: (state) => {
      return {
        reference: state.reference,
        type: state.type,
        amount: state.amount,
        forename: state.forename,
        surname: state.surname,
        email: state.email,
        phone: state.phone,
        currency: state.currency,
        street: state.street,
        zip: state.zip,
        city: state.city,
        state: state.state,
        country: state.country,
        giftaid: state.giftaid,
        gift_donation: state.giftDonation,
        newsletter: state.newsletter,
        tax: objectKeysCamelToSnakeCase(state.tax),
        utms: state.utms
      }
    }
  },
  actions: {
    setReference (payload) { this.reference = payload },
    setType (payload) { this.type = payload },
    setAmount (payload) { this.amount = payload },
    setForename (payload) { this.forename = payload },
    setSurname (payload) { this.surname = payload },
    setEmail (payload) { this.email = payload },
    setPhone (payload) { this.phone = payload },
    setCurrency (payload) { this.currency = payload },
    setStreet (payload) { this.street = payload },
    setZip (payload) { this.zip = payload },
    setCity (payload) { this.city = payload },
    setState (payload) { this.state = payload },
    setCountry (payload) { this.country = payload },
    setGiftaid (payload) { this.giftaid = payload },
    setGiftDonation (payload) { this.giftDonation = payload },
    setNewsletter (payload) { this.newsletter = payload },
    setUtms (payload) { this.utms = payload },
    setTax (payload) { this.tax = payload },
    reset () {
      this.reference = null
      this.type = 'monthly'
      this.amount = null
      this.forename = null
      this.surname = null
      this.email = null
      this.phone = null
      this.currency = null
      this.street = null
      this.zip = null
      this.city = null
      this.state = null
      this.country = 'none'
      this.giftaid = false
      this.giftDonation = null
      this.newsletter = null
      this.utms = {}
      this.tax = { idType: 'none', idNumber: null, taxNumber: null }
    }
  }
})
