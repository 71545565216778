<template>
  <div class="donation-summary">
    <span v-html="summaryText" />
  </div>
</template>

<script>
import { useDonationStore } from '@/stores/donation'
import { useL10nStore } from '@/stores/l10n'

export default {
  name: 'DonationSummary',
  setup () {
    const donationStore = useDonationStore()
    const l10nStore = useL10nStore()
    return { donationStore, l10nStore }
  },
  computed: {
    amount() { return this.donationStore.amount },
    currency() { return this.donationStore.currency },
    formattedCurrency() { return this.$l10n.formatCurrency(this.amount, this.currency, { precision: 2 }) },
    type() { return this.donationStore.type },
    summaryText() { return this.$t(`donationSummary.${this.type}`, { amount: this.formattedCurrency }) }
  }
}
</script>

<style lang="scss" scoped>
  div.donation-summary {
    margin-left: 2rem;
  }
</style>
