export default function serialize (vueInstance) {
  const payment = vueInstance.paymentStore.methods
  const paymentPromise = payment.serialize(vueInstance)

  return paymentPromise.then((paymentParams) => {
    const donation = vueInstance.donationStore.donationParams
    return Object.assign({}, {
      ...donation,
      payment: paymentParams,
      location: vueInstance.$l10n.currentLocation.key,
      language: vueInstance.$l10n.currentLanguage
    })
  })
}
