/* eslint-disable camelcase */
import handleTwoFactorAuth from '@/lib/donation/handleTwoFactorAuth'

class StripeApiKeyError extends Error {
  constructor (message) {
    super(message)
    this.name = 'StripeApiKeyError'
  }
}

function verifyPublicKey (stripe, apiKey) {
  try {
    stripe.createToken('pii', { personal_id_number: 'test' })
      .then(result => {
        if (result.token) {
          console.log('valid stripe apiKey')
        } else {
          throw new StripeApiKeyError(`${apiKey} - Invalid`)
        }
      })
  } catch (error) {
    console.log('Invalid stripe apiKey')
  }
}

function serialize (vueInstance) {
  const donation = vueInstance.donationStore.currentDonation
  const card = vueInstance.stripeCard
  const stripe = vueInstance.paymentStore.methods
  const requiredData = {
    name: `${donation.forename} ${donation.surname}`,
    address_country: donation.country
  }
  const address_line1 = (!!donation.street && donation.street !== '') ? { address_line1: donation.street } : {}
  const address_city = (!!donation.city && donation.city !== '') ? { address_city: donation.city } : {}
  const address_state = (!!donation.state && donation.state !== '') ? { address_state: donation.state } : {}
  const address_zip = (!!donation.zip && donation.zip !== '') ? { address_zip: donation.zip } : {}

  const donationData = Object.assign(requiredData, address_line1, address_city, address_state, address_zip)

  return stripe.createToken(card, donationData)
    .then(result => {
      if (result.error) {
        return Promise.reject(result.error)
      } else {
        return {
          adapter: 'stripe',
          token: result.token.id
        }
      }
    })
}

async function authorize (vueInstance, result, reference) {
  const twoFactorResult = await handleTwoFactorAuth(
    vueInstance.paymentStore.methods, reference, result.data.details.client_secret
  )
  return Promise.resolve(twoFactorResult)
}

function confirmTwoFactor (clientSecret, stripe) {
  return stripe.retrievePaymentIntent(clientSecret)
    .then(result => {
      return stripe.confirmCardPayment(clientSecret, { payment_method: result.paymentIntent.payment_method })
    })
}

export {
  verifyPublicKey,
  authorize,
  confirmTwoFactor,
  serialize
}
