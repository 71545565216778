import { defineStore } from 'pinia'

export const usePaymentStore = defineStore('payment', {
  namespaced: true,
  state: () => {
    return {
      complete: false,
      details: {},
      methods: {}
    }
  },
  getters: {},
  actions: {
    setDetails (payload) { this.details = payload },
    setMethods (payload) { this.methods = payload },
    setComplete (payload) { this.complete = payload },
    reset () {
      this.details = {}
      this.methods = {}
      this.complete = false
    }
  }
})
