<template>
  <div class="thank-you-page">
    <header class="hero">
      <div
        class="image"
        :style="{backgroundImage: `url(${imageSrc})`}"
      />
      <div class="contain">
        <div class="page-title">
          <h1>
            <mark>{{ titleText }} {{ forename }}</mark>
          </h1>
          <h3>
            <mark>{{ formattedMsg }}</mark>
          </h3>
        </div>
      </div>
    </header>
    <div
      v-if="newsletter"
      class="contain newsletter-instructions"
    >
      <h5 v-html="$t('thankYou.newletter_instructions')" />
    </div>
    <SocialShare />
    <ThankYouCampaigns />
    <ThankYouGalleries />
  </div>
</template>

<script>
import ThankYouGalleries from './thankyou/layout/ThankYouGalleries'
import ThankYouCampaigns from './thankyou/layout/ThankYouCampaigns'
import SocialShare from '@/components/SocialShare'
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import currency from 'currency.js'
import { useDonationStore } from '@/stores/donation'
import { useL10nStore } from '@/stores/l10n'

export default {
  name: 'ThankYou',
  components: {
    ThankYouGalleries,
    ThankYouCampaigns,
    SocialShare
  },
  setup () {
    const l10nStore = useL10nStore()
    const donationStore = useDonationStore()
    const siteData = reactive({ title: '' })
    useHead({
      title: computed(() => siteData.title)
    })
    return { siteData, donationStore, l10nStore }
  },
  beforeMount () {
    this.siteData.title = this.$t('thankYou.header.default_title') + ' - Survival International'
  },
  computed: {
    titleText () { return this.$t('thankYou.header.default_title') },
    thanksText () {
      return this.type === 'oneOff' ? 'thankYou.header.yourDonationOfX.oneOff' : 'thankYou.header.yourDonationOfX.regular'
    },
    newsletter () { return this.donationStore.newsletter },
    imageSrc () { return this.$t('thankYou.header.default_image') },
    amount () { return this.donationStore.amount },
    curr () { return this.donationStore.currency },
    currencies () {
      if (this.l10nStore['current']) {
        return this.l10nStore.current['currencies']
      } else { return null }
    },
    forename () { return this.donationStore.forename },
    type () { return this.donationStore.type },
    formattedCurrency () {
      if (this.currencies) {
        return this.formatCurrency(this.amount, this.curr, this.currencies)
      } else { return '' }
    },
    formattedMsg () { return this.$t(this.thanksText, { amount: this.formattedCurrency }) }
  },
  methods: {
    removeLocalStorage () { localStorage.clear() },
    formatCurrency (amount, curr, currencies) {
      return currency(
        amount, Object.assign({ formatWithSymbol: true }, currencies[curr].config), null
      ).format()
    }
  },
  mounted () {
    this.$scrollToTop()
    this.$nextTick(function () { this.removeLocalStorage() })
  }
}
</script>

<style lang="scss" scoped>
  .hero{
    .page-title{
      margin: auto 0 0 auto;
    }
  }
  .newsletter-instructions {
    background-color: #f2f2f2;
    margin-top: 20px;
    border-radius: 5px;
    padding: 0 1rem 0 1rem;
    h5 {
      letter-spacing: 0.4pt;
    }
  }
</style>
