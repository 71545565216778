import currency from 'currency.js'

const countries = {
  /* This could reside in location JSON ? */
  'es': { baseRate: 0.8, higherRate: 0.4, baseLimit: 250.00, higherLimit: null },
  'fr': { baseRate: 0.66, higherRate: null, baseLimit: null, higherLimit: null },
  'it': { baseRate: 0.7, higherRate: null, baseLimit: null, higherLimit: 30000.00 }
}

function annualizedAmount(amountObj, frequency) {
  switch (frequency) {
    case 'monthly':
      return amountObj.multiply(12)
    case 'quarterly':
      return amountObj.multiply(4)
    case 'biAnnually':
      return amountObj.multiply(2)
    default:
      return amountObj
  }
}

function installmentAmountFromAnnual(amountObj, frequency) {
  switch (frequency) {
    case 'monthly':
      return amountObj.divide(12)
    case 'quarterly':
      return amountObj.divide(4)
    case 'biAnnually':
      return amountObj.divide(2)
    default:
      return amountObj
  }
}

function calculateEffectiveRelief(amountObj, frequency, tax) {
  const annualAmount = annualizedAmount(amountObj, frequency)

  if (annualAmount.value > tax.baseLimit) {
    let baseAmount = currency(tax.baseLimit)
    let baseReduction = baseAmount.multiply(tax.baseRate)
    let higherAmount = annualAmount.subtract(baseAmount)
    let higherReduction = higherAmount.multiply(tax.higherRate)

    let annualReducedAmount = baseReduction.add(higherReduction)
    let reducedAmount = installmentAmountFromAnnual(annualReducedAmount, frequency).value
    let deduction = amountObj.subtract(reducedAmount).value
    return { reducedAmount, deduction, ineligible: false }
  } else {
    let reducedAmount = amountObj.multiply(tax.baseRate).value
    let deduction = amountObj.subtract(reducedAmount).value

    return { reducedAmount, deduction, ineligible: false }
  }
}

function calculateTaxRelief(amount, frequency, country) {
  if (!(amount && frequency && country)) { return { reducedAmount: null, deduction: null, ineligible: false } }
  const amountObj = currency(amount)
  const tax = countries[country]

  /* Currently NOT taking into account contributions over one (tax) year if regular donation */
  if (tax.higherLimit && (amountObj.value > tax.higherLimit)) { return { reducedAmount: null, deduction: null, ineligible: true } }
  /*
    If tax has different rates dependant on amount total,
    calculate the effective relief (per installment if regular donation)
  */
  if (tax.higherRate && tax.baseLimit) { return calculateEffectiveRelief(amountObj, frequency, tax) }

  /* If no edge cases above calculate default */
  let reducedAmount = amountObj.multiply(tax.baseRate).value
  let deduction = amountObj.subtract(reducedAmount).value

  return { reducedAmount, deduction, ineligible: false }
}

export { calculateTaxRelief }
