<template>
  <div class="privacy-statement">
    <h3>{{ $t('donationForm.details.privacy.title') }}</h3>
    <div>
      <small v-html="$t('donationForm.details.privacy.text')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyStatement'
}
</script>

<style lang="scss" scoped>
  h3 {
    color: white;
    font-family: $titles-font-family-medium;
    margin-bottom: 0.5rem;
  }
</style>
