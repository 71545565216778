<template>
  <div class="collection-date-select">
    <label>{{ $t('donationForm.details.smartDebit.collectionDate.label') }}:</label>
    <div class="options">
      <div
        class="option"
        :class="`day-${key}`"
        v-for="(day, key) in availableCollectionDates"
        :key="key"
      >
        <input
          type="radio"
          :id="idFor(key)"
          v-model="model"
          :value="key"
        >
        <label :for="idFor(key)">{{ day }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'

export default {
  name: 'CollectionDateSelect',
  setup () {
    const uniqueId = ref(uuid())
    return { uniqueId }
  },
  props: {
    modelValue: { type: String, default: '1' }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get () { return this.modelValue },
      set (newValue) { this.$emit('update:modelValue', newValue) }
    },
    availableCollectionDates () { return this.$l10n.availableCollectionDates }
  },
  methods: {
    idFor (day) { return `element-${this.uniqueId}-${day}` }
  }
}
</script>

<style lang="scss" scoped>
  .collection-date-select {
    display: flex;
    margin-bottom: 0.5em;

    label {
      line-height: 40px;
      font-weight: bold;
    }
    .options {
      flex: 1;
      margin-left: 1em;
      display: flex;
      flex-direction: row;

      .option {
        margin-right: 0.5em;

        input {
          display: none;
        }

        label {
          display: block;
          width: 43px;
          height: 43px;
          border-radius: 100%;
          background-color: white;
          color: $color-blue-lagoon;
          font-size: 20px;
          line-height: 40px;
          text-align: center;
          font-weight: bold;
          opacity: 0.4;
          transition: opacity 0.3s ease-in-out;
          cursor: pointer;
        }

        input:checked + label {
          opacity: 1;
          cursor: default;
        }
      }
    }
  }
</style>
