<template>
  <div
    class="gift-aid"
    v-if="display"
  >
    <h4>{{ $t('donationForm.details.giftaid.title') }}</h4>
    <p>{{ $t('donationForm.details.giftaid.description', { amount: amountWithGiftAid }) }}</p>
    <div>
      <img
        class="giftaid-logo"
        :src="$t('donationForm.details.giftaid.logo')"
        alt="Giftaid logo"
      >
      <CheckBox
        :label="$t('donationForm.details.giftaid.label')"
        v-model="giftaid"
      />
    </div>
  </div>
</template>

<script>
import CheckBox from '@/components/form/CheckBox'
import { useDonationStore } from '@/stores/donation'
import currency from 'currency.js'

export default {
  name: 'GiftAid',
  setup () {
    const donationStore = useDonationStore()
    return { donationStore }
  },
  components: { CheckBox },
  computed: {
    giftaid: {
      get () { return this.donationStore.giftaid },
      set (newValue) { this.donationStore.setGiftaid(newValue) }
    },
    amount () { return this.donationStore.amount },
    amountWithGiftAid () { return currency(this.amount).multiply(0.25) },
    currency () { return this.donationStore.currency },
    country () { return this.donationStore.country },
    display () {
      return ((this.currency === 'gbp') && (this.country === 'GB'))
    }
  },
  watch: {
    currency () {
      if (this.currency !== 'gbp') { this.giftaid = false }
    }
  }
}
</script>

<style lang="scss" scoped>
$text-color: #fff;
h4 {
  color: $text-color;
  font-family: 'Stag-medium';
  display: inline;
  &:after{
    content: ' - ';
    display: inline;
  }
}
img.giftaid-logo {
  width: 10rem;
  margin: 0.5rem;
}

.gift-aid {
  font-size: 0.9rem;
  background: rgba(255,255,255,0.3);
  color: $text-color;
  padding: 0.75rem;
  margin-top: 1rem;

  .giftaid-logo {
    display: block;
  }
  p {
    margin-top: 0.1rem;
    font-size: 1.1rem;
    display: inline;
  }
}
</style>
