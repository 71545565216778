import { defineStore } from 'pinia'

export const useL10nStore = defineStore('l10n', {
  namespaced: true,
  state: () => {
    return {
      current: null,
      loadedLocations: {}
    }
  },
  getters: {},
  actions: {
    addLocation ({ key, locationData }) {
      this.loadedLocations[key] = Object.assign({}, locationData, { key: key })
    },
    set (location) {
      this.current = this.loadedLocations[location]
    },
    setCurrentLocation (location) { this.current = location },
    change (key) {
      let promise = null

      if (this.loadedLocations[key]) {
        promise = Promise.resolve(key)
      } else {
        promise = import(`@/locations/${key}`)
          .then(module => { return module.default })
          .then(locationData => {
            this.addLocation({ key, locationData })
            return key
          })
      }

      return promise
        .then((key) => this.set(key))
    }
  }
})
