<template>
  <div class="page-top hero">
    <div
      class="image"
      :style="{backgroundImage: `url(${imageSrc})`}"
    />
    <TransitionSlide>
      <div class="header">
        <div class="contain">
          <div class="page-title">
            <h1><mark>{{ donateText }}</mark></h1>
            <h2 :class="(titleText.length < 120) ? '' : 'long-text'">
              <mark>{{ titleText }}</mark>
            </h2>
            <mark class="title-credit">
              <strong>{{ titleTextCredit.title }}</strong>
              {{ titleTextCredit.description }}
            </mark>
          </div>
        </div>
        <div class="row">
          <div class="right" />
        </div>
      </div>
    </TransitionSlide>
    <div class="container-wrapper">
      <DonationContainer>
        <slot />
      </DonationContainer>
    </div>
  </div>
</template>

<script>
import DonationContainer from './DonationContainer'
import TransitionSlide from '@/transitions/Slide'
import { useRootStore } from '@/stores/root'

export default {
  name: 'DonationHeader',
  setup () {
    const rootStore = useRootStore()
    return { rootStore }
  },
  components: { TransitionSlide, DonationContainer },
  computed: {
    appeal () { return this.rootStore.appeal },
    donateText () { return this.appeal ? this.$t(`appeals.${this.appeal}.donate_text`) : this.$t('header.donate_text') },
    titleText () { return this.appeal ? this.$t(`appeals.${this.appeal}.title`) : this.$t('header.default_title') },
    titleTextCredit () { return this.appeal ? this.$t(`appeals.${this.appeal}.credit`) : this.$tm('header.default_title_credit') },
    imageSrc () { return this.appeal ? this.$t(`appeals.${this.appeal}.image`) : this.$t('header.default_image') }
  }
}
</script>

<style lang="scss" scoped>
  #donation{
    background: #fff;
  }
  .page-top {
    color: white;
    position: relative;
    overflow: initial;
    background-color: white;
    flex-direction: column;
    max-height: initial;
    min-height: 20vh;
    transition: min-height 250ms ease-in-out;

    @include on-non-mobile(){
        margin-bottom: 300px;
    }

    .contain{
      display: flex;
      justify-content: flex-end;
      position: relative;
    }
  }

  .header {
    position: relative;
  }

  .page-title {
    padding: 1.2em 2em;
    margin: 0 0 0 auto;

    width: 35em;
    min-width: 600px;
    max-width: 13em;
    display: inline-block;

    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      mark {
        font-family: $base-font-family;
        box-decoration-break: clone;
        background-color: #2C2A29;
        background-blend-mode: multiply;
        padding: 0.2em 0;
        &:before {
          height: 1rem;
          width: 0.3rem;
          background-color: #B33D26;
          display: inline-block;
          margin-right: 0.3rem;
          content: '';
        }
      }
    }

    h2 {
      font: 1.8em $titles-font-family;
      text-shadow: 0 0 2px $color-body-color;
      margin: 1rem 0 1rem;
      @include on-mobile(){
        margin: 0.8rem 0 0.4rem;
      }
      @include on-non-mobile(){
        font: 3em $titles-font-family;
      }
      mark {
        box-decoration-break: clone;
        background-color: #2C2A29;
        background-blend-mode: multiply;
        padding: 0.2em 0;
      }
    }

    h2.long-text {
      @include on-non-mobile(){
        font: 2.2em $titles-font-family;
      }
    }

    .title-credit {
      font-family: $base-font-family;
      padding: 0.2em 0;
    }
  }

  .container-wrapper {
    margin: 0;
    transition: all 0.3s;

    @include on-non-mobile(){
      margin: 0 2em -250px;
    }
  }

  .row {
    .right {
      max-width: 50%;
      float: right;
      padding-right: 6em;
    }

    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
</style>
