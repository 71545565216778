<template>
  <div class="amount-options">
    <div class="options">
      <div
        class="option"
        :class="[ name, { active: isActive(option) }]"
        v-for="(option, name) in suggestedAmounts"
        :key="name"
        @click="select(option)"
      >
        <span class="amount">{{ formatCurrency(option) }}</span>
        <span>{{ $t(`donationForm.suggestedAmounts.${type}.caption`) }}</span>
        <span
          class="caption"
          v-if="$tm(`donationForm.suggestedAmounts.${type}.${name}`)[0]"
        >
          {{ $t(`donationForm.suggestedAmounts.${type}.${name}`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useDonationStore } from '@/stores/donation'
import { ref } from 'vue'

export default {
  name: 'SuggestedAmounts',
  setup () {
    const donationStore = useDonationStore()
    const selectedAmount = ref(null)
    return { donationStore, selectedAmount }
  },
  emits: ['selected'],
  computed: {
    type () { return this.donationStore.type },
    currency () { return this.donationStore.currency },
    currentAmount () { return this.donationStore.amount },
    suggestedAmounts () { return this.$l10n.suggestedAmountsFor(this.currency, this.type) },
  },
  methods: {
    formatCurrency (value) {
      return this.$l10n.formatCurrency(value, this.currency, { precision: 0 })
    },
    select (value) {
      this.selectedAmount = value
      this.$emit('selected', value)
    },
    isActive (val) { return val === this.currentAmount }
  },
  watch: {
    'currentAmount': function (val) {
      if (val !== this.selectedAmount) {
        this.selectedAmount = null
      } else {
        this.isActive(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $border-color: lighten($color-blue-lagoon, 20%);

  .options {
    display: flex;
    flex-direction: row;
    @include on-mobile{
      flex-direction: column;
    }
  }

  div.active {
    background-color: $color-web-orange;
    color: #000;
  }

  .option {
    flex: 1 1 0px;
    text-align: center;
    color: $color-faded-btn-text;
    margin: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 1rem 0.5rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover {
      background-color: $color-web-orange;
      color: #000;
    }

    span {
      display: block;
      padding: 0 0.5m;
    }

    .amount {
      font-size: 1.5em;
      font-weight: bold;
    }

    .caption {
      font-size: 0.8em;
      margin-bottom: 1.5rem;
    }

    button {
      margin: auto 0.5rem 0;
      padding: 0.6em 1em;
      width: calc(100% - 1rem);
      outline: none;
      box-shadow: 2px 2px 6px rgba(black, 0.5);
      border: none;
      cursor: pointer;
      background-color: $color-web-orange;
      text-transform: uppercase;
      font-size: 0.9em;
      font-weight: bold;

      @include on-mobile{
        margin: 1rem;
        width: auto;
      }

      &:active {
        transform: translate(1px, 1px);
        box-shadow: 1px 1px 4px rgba(black, 0.5);
      }
    }
  }
</style>
