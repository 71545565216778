// This match a country to one of our 6 Survival offices
// Denoted by the office locale:
/*
  de - German office
  es - Spanish office
  gb - UK office
  fr - French office
  it - Italian office
  pt - Brasil office
  us -  US office
*/
// Currently only mapping for EN domains. (gb & us)
export default {
  AD: 'gb',
  AE: 'gb',
  AF: 'gb',
  AG: 'us',
  AI: 'us',
  AL: 'gb',
  AM: 'gb',
  AO: 'gb',
  AQ: 'gb',
  AR: 'us',
  AS: 'gb',
  AT: 'gb',
  AU: 'gb',
  AW: 'us',
  AX: 'gb',
  AZ: 'gb',
  BA: 'gb',
  BB: 'us',
  BD: 'gb',
  BE: 'gb',
  BF: 'gb',
  BG: 'gb',
  BH: 'gb',
  BI: 'gb',
  BJ: 'gb',
  BL: 'gb',
  BM: 'us',
  BN: 'gb',
  BO: 'us',
  BQ: 'gb',
  BR: 'gb',
  BS: 'us',
  BT: 'gb',
  BW: 'gb',
  BY: 'gb',
  BZ: 'us',
  CA: 'us',
  CC: 'gb',
  CD: 'gb',
  CF: 'gb',
  CG: 'gb',
  CH: 'gb',
  CI: 'gb',
  CK: 'gb',
  CL: 'us',
  CM: 'gb',
  CN: 'gb',
  CO: 'us',
  CR: 'us',
  CU: 'us',
  CV: 'gb',
  CW: 'us',
  CX: 'gb',
  CY: 'gb',
  CZ: 'gb',
  DE: 'gb',
  DJ: 'gb',
  DK: 'gb',
  DM: 'us',
  DO: 'us',
  DZ: 'gb',
  EC: 'us',
  EE: 'gb',
  EG: 'gb',
  EH: 'gb',
  ER: 'gb',
  ES: 'gb',
  ET: 'gb',
  FI: 'gb',
  FJ: 'gb',
  FK: 'gb',
  FM: 'gb',
  FO: 'gb',
  FR: 'gb',
  GA: 'gb',
  GB: 'gb',
  GD: 'us',
  GE: 'gb',
  GF: 'us',
  GG: 'gb',
  GH: 'gb',
  GI: 'gb',
  GL: 'gb',
  GM: 'gb',
  GN: 'gb',
  GP: 'us',
  GQ: 'gb',
  GR: 'gb',
  GS: 'gb',
  GT: 'us',
  GU: 'gb',
  GW: 'gb',
  GY: 'us',
  HK: 'gb',
  HM: 'gb',
  HN: 'us',
  HR: 'gb',
  HT: 'us',
  HU: 'gb',
  ID: 'gb',
  IE: 'gb',
  IL: 'gb',
  IM: 'gb',
  IN: 'gb',
  IO: 'gb',
  IQ: 'gb',
  IR: 'gb',
  IS: 'gb',
  IT: 'gb',
  JE: 'gb',
  JM: 'us',
  JO: 'gb',
  JP: 'gb',
  KE: 'gb',
  KG: 'gb',
  KH: 'gb',
  KI: 'gb',
  KM: 'gb',
  KN: 'us',
  KP: 'gb',
  KR: 'gb',
  KW: 'gb',
  KY: 'us',
  KZ: 'gb',
  LA: 'gb',
  LB: 'gb',
  LC: 'us',
  LI: 'gb',
  LK: 'gb',
  LR: 'gb',
  LS: 'gb',
  LT: 'gb',
  LU: 'gb',
  LV: 'gb',
  LY: 'gb',
  MA: 'gb',
  MC: 'gb',
  MD: 'gb',
  ME: 'gb',
  MF: 'gb',
  MG: 'gb',
  MH: 'gb',
  MK: 'gb',
  ML: 'gb',
  MM: 'gb',
  MN: 'gb',
  MO: 'gb',
  MP: 'gb',
  MQ: 'us',
  MR: 'gb',
  MS: 'us',
  MT: 'gb',
  MU: 'gb',
  MV: 'gb',
  MW: 'gb',
  MX: 'us',
  MY: 'gb',
  MZ: 'gb',
  NA: 'gb',
  NC: 'gb',
  NE: 'gb',
  NF: 'gb',
  NG: 'gb',
  NI: 'us',
  NL: 'gb',
  NO: 'gb',
  NP: 'gb',
  NR: 'gb',
  NU: 'gb',
  NZ: 'gb',
  OM: 'gb',
  PA: 'us',
  PE: 'us',
  PF: 'gb',
  PG: 'gb',
  PH: 'gb',
  PK: 'gb',
  PL: 'gb',
  PM: 'gb',
  PN: 'gb',
  PR: 'us',
  PS: 'gb',
  PT: 'gb',
  PW: 'gb',
  PY: 'us',
  QA: 'gb',
  RE: 'gb',
  RO: 'gb',
  RS: 'gb',
  RU: 'gb',
  RW: 'gb',
  SA: 'gb',
  SB: 'gb',
  SC: 'gb',
  SD: 'gb',
  SE: 'gb',
  SG: 'gb',
  SH: 'gb',
  SI: 'gb',
  SJ: 'gb',
  SK: 'gb',
  SL: 'gb',
  SM: 'gb',
  SN: 'gb',
  SO: 'gb',
  SR: 'us',
  SS: 'gb',
  ST: 'gb',
  SV: 'us',
  SX: 'gb',
  SY: 'gb',
  SZ: 'gb',
  TC: 'gb',
  TD: 'gb',
  TF: 'gb',
  TG: 'gb',
  TH: 'gb',
  TJ: 'gb',
  TK: 'gb',
  TL: 'gb',
  TM: 'gb',
  TN: 'gb',
  TO: 'gb',
  TR: 'gb',
  TT: 'us',
  TV: 'gb',
  TW: 'gb',
  TZ: 'gb',
  UA: 'gb',
  UG: 'gb',
  UK: 'gb',
  UM: 'gb',
  US: 'us',
  UY: 'us',
  UZ: 'gb',
  VA: 'gb',
  VC: 'us',
  VE: 'us',
  VG: 'us',
  VI: 'us',
  VN: 'gb',
  VU: 'gb',
  WF: 'gb',
  WS: 'gb',
  YE: 'gb',
  YT: 'gb',
  ZA: 'gb',
  ZM: 'gb',
  ZW: 'gb'
}
