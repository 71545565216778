import storageAvailable from '@/utilities/storageAvailable'

export default function loadDonation (vueInstance, lokalStorage) {
  if (storageAvailable(lokalStorage)) {
    let storedDonation = {}
    if (lokalStorage.getItem('donation')) {
      storedDonation = JSON.parse(lokalStorage.getItem('donation'))
      vueInstance.donationStore.setReference(storedDonation.reference)
      vueInstance.donationStore.setType(storedDonation.type)
      vueInstance.donationStore.setAmount(storedDonation.amount)
      vueInstance.donationStore.setForename(storedDonation.forename)
      vueInstance.donationStore.setSurname(storedDonation.surname)
      vueInstance.donationStore.setEmail(storedDonation.email)
      vueInstance.donationStore.setPhone(storedDonation.phone)
      vueInstance.donationStore.setCurrency(storedDonation.currency)
      vueInstance.donationStore.setStreet(storedDonation.street)
      vueInstance.donationStore.setZip(storedDonation.zip)
      vueInstance.donationStore.setCity(storedDonation.city)
      vueInstance.donationStore.setState(storedDonation.state)
      vueInstance.donationStore.setCountry(storedDonation.country)
      vueInstance.donationStore.setGiftaid(storedDonation.giftaid)
      vueInstance.donationStore.setNewsletter(storedDonation.newsletter)
      vueInstance.donationStore.setUtms(storedDonation.utms)
      return storedDonation
    } else { return false } // raise error instead?
  }
}
