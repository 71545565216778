<template>
  <div
    class="currency-select"
    v-if="currencyCount > 1"
  >
    <label>{{ $t(`donationForm.currency.label`) }}:</label>
    <div class="options">
      <div
        class="option"
        :class="key"
        v-for="(curr, key) in availableCurrencies"
        :key="key"
      >
        <input
          type="radio"
          :id="idFor(key)"
          v-model="currency"
          :value="key"
        >
        <label
          :class="{ 'small-font': largeSymbol(curr.config.symbol) }"
          :for="idFor(key)"
        >
          {{ curr.config.symbol }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import { useDonationStore } from '@/stores/donation'
import { ref } from 'vue'

export default {
  name: 'CurrencySelect',
  setup () {
    const donationStore = useDonationStore()
    const uniqueId = ref(uuid())
    return { donationStore, uniqueId }
  },
  computed: {
    currency: {
      get () { return this.donationStore.currency },
      set (newValue) { this.donationStore.setCurrency(newValue) }
    },
    availableCurrencies () { return this.$l10n.availableCurrencies },
    currencyCount () { return Object.keys(this.availableCurrencies).length }
  },
  methods: {
    idFor (currency) { return `element-${this.uniqueId}-${currency}` },
    largeSymbol (symbol) { return (symbol.length > 2) }
  },
  watch: {
    '$l10n.currentLocation': function (_val) {
      if (this.currency === null) {
        this.donationStore.setCurrency(this.$l10n.currentLocation.defaultCurrency)
      } else if (!Object.keys(this.$l10n.availableCurrencies).includes(this.currency)) {
        this.donationStore.setCurrency(this.$l10n.currentLocation.defaultCurrency)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .currency-select {
    display: flex;
    margin-bottom: 1em;

    label {
      line-height: 40px;
      font-weight: bold;
    }
    .options {
      flex: 1;
      margin-left: 1em;
      display: flex;
      flex-direction: row;

      .option {
        margin-right: 0.5em;

        input {
          display: none;
        }

        .small-font {
          font-size: 14px;
        }

        label {
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: rgba(255, 255, 255, 0.3);
          color: $color-faded-btn-text;
          font-size: 20px;
          line-height: 40px;
          text-align: center;
          font-weight: bold;
          transition: opacity 0.3s ease-in-out;
          cursor: pointer;
        }

        input:checked + label {
          opacity: 1;
          color: black;
          background-color: $color-web-orange;
          cursor: default;
        }
      }
    }
  }
</style>
