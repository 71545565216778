import axios from 'axios'

export default function awaitUpdate (vueInstance, reference) {
  vueInstance.$scrollToElement('.loading')

  return new Promise((resolve) => {
    const check = async () => {
      const result = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/donation/${reference}/status`)
      const payment = vueInstance.paymentStore.methods

      if (result.data.success && result.data.status !== 'pending') {
        const isIncomplete = Object.prototype.hasOwnProperty.call(result.data.details, 'auth_required') || Object.prototype.hasOwnProperty.call(result.data.details, 'incomplete') || result.data.details.status === 'incomplete' || result.data.details.status === 'requires_confirmation'
        if (isIncomplete) {
          vueInstance.paymentStore.setDetails(result.data.details)
          resolve(payment.authorize(vueInstance, result, reference))
        } else {
          resolve(result.data)
        }
      } else {
        setTimeout(check, 500)
      }
    }
    check()
  })
}
