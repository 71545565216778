<template>
  <div
    class="form-field selectfield"
    :class="{ error: hasError && !active }"
  >
    <label
      v-if="label"
      :for="id"
      :class="{ active: active || hasValue }"
    >
      {{ label }}
    </label>

    <select
      :id="id"
      v-model="val"
      v-on="{focus, blur}"
      :class="{ hasValue: hasValue }"
    >
      <option
        value="none"
        disabled
      />
      <template
        v-for="(key, value) in list"
        :key="key"
      >
        <option
          v-if="value !== 'disabled'"
          :value="value"
        >
          {{ key }}
        </option>
        <option
          v-else
          value="none"
          disabled
        >
          {{ key }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'

export default {
  name: 'SelectField',
  setup () {
    const active = ref(false)
    return { active }
  },
  props: {
    modelValue: { type: [String, Number], required: true, default: 'none' },
    label: { type: String, default: null },
    list: { type: Object, required: true },
    validation: { type: Object, required: true }
  },
  emits: ['update:modelValue'],
  computed: {
    id () { return `element-${uuid()}` },
    hasError () { return this.validation && this.validation.$error },
    val: {
      get () { return this.modelValue },
      set (newValue) {
        this.validation && this.validation.$touch()
        this.$emit('update:modelValue', newValue)
      }
    },
    hasValue () { return this.modelValue && this.modelValue !== 'none' }
  },
  methods: {
    focus () { this.active = true },
    blur () {
      this.validation && this.validation.$touch()
      this.active = false
    }
  },
  watch: {
    list () {
      if (this.val === '') { this.val = 'none' }
    }
  }
}
</script>
<style lang="scss" scoped>
  input, select {
    cursor: pointer !important;
  }
</style>
