import { defineStore } from 'pinia'

export const useRootStore = defineStore('root', {
  namespaced: true,
  state: () => {
    return {
      fullMode: false,
      currentDomain: null,
      isEnDomain: false,
      queryParams: {},
      omittedFrequency: null,
      appeal: null,
      validation: {
        paymentMethodSelect: false,
        form: false
      },
      validateAllFields: false
    }
  },
  getters: {
    formValid: state => {
      return Object.values(state.validation).every(item => item === true)
    }
  },
  actions: {
    setFullMode (payload) { this.fullMode = payload },
    setCurrentDomain (payload) { this.currentDomain = payload },
    setIsEnDomain (payload) { this.isEnDomain = payload },
    setOmittedFrequency (payload) { this.omittedFrequency = payload },
    setAppeal (payload) { this.appeal = payload },
    setQueryParams (payload) { this.queryParams = payload },
    setValidationPaymentMethodSelect (payload) { this.validation.paymentMethodSelect = payload },
    setValidationForm (payload) { this.validation.form = payload },
    touchValidationFields (payload) { this.validateAllFields = payload }
  }
})
