<template>
  <nav>
    <div class="contain">
      <img
        class="logo"
        src="https://assets.survivalinternational.org/viru/layout/survival-logo.svg"
      >
      {{ $t('footer.main.text') }}
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    currentLanguage () { return this.$l10n.currentLanguage }
  }
}
</script>

<style lang="scss" scoped>
  nav{
    background-color: #fff;
    padding: 0.5rem;
    font-size: 0.875rem;

    @include on-non-mobile(){
      padding: 1rem;
    }

    .contain{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img{
      height: 40px;
      margin: 0 1rem 0 0.5rem;

      @include on-non-mobile(){
        height: 72px;
      }
    }
  }
</style>
