<template>
  <transition name="fade">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionFade'
}
</script>

<style lang="scss" scoped>
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
</style>
