<template>
  <div id="donation">
    <DonationHeader>
      <LoadingPanel
        :promise="submissionPromise"
        @success="handleSuccess"
      >
        <template #loading>
          <font-awesome-icon
            icon="spinner"
            pulse
          />
          <h4>{{ $t('paymentStatus.processing') }}</h4>
        </template>
        <template #failure="failure">
          <h4>{{ $t('paymentStatus.failure') }}</h4>
          <span class="error">{{ failure.result.failure_message }}</span>
          <button @click="resetPayment">
            {{ $t('paymentStatus.changePaymentMethod') }}
          </button>
        </template>
      </LoadingPanel>
      <div class="columns">
        <div class="column">
          <ProgressTracker :current-step="3" />
          <DonationSummary />
          <PaymentMethodSelect @set-stripe-card="setStripeCard" />
          <vue-recaptcha
            :sitekey="recaptchaPublicKey"
            :load-recaptcha-script="true"
            @verify="onVerify"
            @expired="onExpired"
            size="invisible"
            ref="invisibleRecaptcha"
            recaptcha-host="www.recaptcha.net"
          >
            <button
              class="submit"
              @click="submit"
              :class="{ disabled: !donationValid }"
            >
              {{ $t('donationForm.confirm') }}
            </button>
          </vue-recaptcha>
          <p class="donation-nav">
            <router-link
              :to="{ name: $t('routeNames.personalDetails') }"
              class="btn btn-primary back"
            >
              <font-awesome-icon icon="arrow-left" />
              {{ $t('donationForm.back') }}
            </router-link>
          </p>
        </div>
      </div>
    </DonationHeader>
    <DonationFooter />
  </div>
</template>

<script>
import DonationHeader from './donation/layout/DonationHeader'
import DonationFooter from './donation/layout/DonationFooter'
import submitDonation from '@/lib/donation/submitDonation'
import PaymentMethodSelect from '@/components/donation/PaymentMethodSelect'
import ProgressTracker from '@/components/donation/ProgressTracker'
import DonationSummary from '@/components/donation/DonationSummary'
import LoadingPanel from '@/components/LoadingPanel'
import { v4 as uuid } from 'uuid'
import { VueRecaptcha } from 'vue-recaptcha'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'

export default {
  name: 'PaymentDetails',
  components: {
    DonationFooter,
    DonationHeader,
    PaymentMethodSelect,
    LoadingPanel,
    VueRecaptcha,
    ProgressTracker,
    DonationSummary
  },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const submissionPromise = ref(null)
    const recaptchaPublicKey = ref(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY)
    const recaptchaResponse = ref(null)
    const recaptchaTimout = ref(null)
    const stripeCard = ref(null)
    return {
      donationStore, paymentStore, rootStore, submissionPromise,
      recaptchaPublicKey, recaptchaResponse, recaptchaTimout, stripeCard
    }
  },
  computed: {
    thankYouPath () { return this.$t('routeNames.thankYou') },
    donationValid () { return this.rootStore.formValid },
    isSuspectedBot () { return !this.recaptchaResponse },
  },
  methods: {
    submit () { submitDonation(this, localStorage) },
    resetPayment () {
      this.donationStore.setReference(uuid())
      this.paymentStore.reset()
      this.submissionPromise = null
    },
    handleSuccess () { this.$router.push({ name: this.thankYouPath }) },
        onVerify (response) {
      this.recaptchaTimout = false
      this.recaptchaResponse = response
    },
    onExpired () {
      this.recaptchaTimout = true
      this.$refs.invisibleRecaptcha.reset()
      this.recaptchaResponse = null
    },
    setStripeCard (card) { this.stripeCard = card } // The submit > serialize method requires this, and it cannot be stored within vuex (dynamic)
  },
  watch: {
    donationValid: function (valid) {
      // If form is now valid, run recaptcha before submit clicked
      if (valid && (this.$refs['invisibleRecaptcha'])) { this.$refs.invisibleRecaptcha.execute() }
    },
    recaptchaTimout: function (val) {
      if (val && (this.$refs['invisibleRecaptcha'])) {
        this.$refs.invisibleRecaptcha.execute()
      }
    }
  },
  mounted () {
    this.$scrollToElement('.column')
  }
}
</script>

<style lang="scss" scoped>
  .columns {
    @include on-desktop(){
      display: flex;
      margin: 0 -0.5rem;
    }

    .column {
      margin-bottom: 1.5rem;

       @include on-desktop(){
        flex: 1;
        margin: 0;
        padding: 0.5rem 1rem;
      }
    }
  }

  button.submit {
    border: none;
    padding: 0.6em 1em;
    text-align: center;
    width: 100%;
    margin-top: 1em;
    background-color: orange;
    color: black;
    text-shadow: 0 1px 0 white;
    box-shadow: 2px 2px 7px rgba(black, 0.3);

    &.disabled {
      opacity: 0.7;
    }
  }
</style>
