import { createI18n } from 'vue-i18n'

export const i18n = createI18n(
  {
    // Below should work from vue-i18n v8.8+
    // https://kazupon.github.io/vue-i18n/api/#constructor-options
    // "should" stop vue complaining about: this.$i18n.t
    silentFallbackWarn: true,
    warnHtmlInMessage: false,
    warnHtmlMessage: false,
    globalInjection: true,
    legacy: false
  }
)
