<template>
  <div class="sepa-payment">
    <small v-if="$tm('payment.methods.sepa.msg')[0]">{{ $t('payment.methods.sepa.msg') }}</small>
    <div>
      <FormField
        :label="showAsteriskIfRequired('iban') + $t('donationForm.details.sepa.iban.label')"
        v-model="sepa.iban"
        :validation="v$.sepa.iban"
        theme="light"
      />
    </div>
    <div
      class="confirm-payment-holder"
      v-if="$l10n.currentLocation.formFields.confirmPaymentHolderRequired"
    >
      <CheckBox
        :label="$t('payment.confirmPaymentHolder')"
        v-model="assumedPaymentHolder"
      />
    </div>
  </div>
</template>

<script>
import FormField from '@/components/form/FormField'
import CheckBox from '@/components/form/CheckBox'
import Iban from 'iban'
import { v4 as uuid } from 'uuid'
import { serialize } from './methods'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { ref } from 'vue'

export default {
  name: 'SepaComponent',
  components: { FormField, CheckBox },
  setup () {
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const sepa = ref({ iban: '' })
    const assumedPaymentHolder = ref(true)
    return {
      donationStore, paymentStore, v$: useVuelidate(), sepa, assumedPaymentHolder
    }
  },
  props: {
    validateFields: { type: Boolean, default: false }
  },
  validations: {
    sepa: {
      iban: {
        required,
        iban: value => Iban.isValid(value)
      }
    }
  },
  methods: {
    showAsteriskIfRequired (field) {
      const fieldRequired = this.v$.sepa[field].required
      if (this.v$.sepa[field] === 'none') {
        return '* '
      }
      return (fieldRequired && fieldRequired.$invalid) ? '* ' : ''
    }
  },
  computed: {
    sepaInvalid () {
      if (this.assumedPaymentHolder === false) { return true }
      return this.v$['sepa'] ? this.v$.sepa.$invalid : true
    }
  },
  watch: {
    'sepa.iban': function (val) {
      if (val) {
        this.sepa.iban = val.toUpperCase().replace(/[^A-Za-z0-9]/g, '')
        this.paymentStore.setDetails({ ...this.sepa })
      }
    },
    validateFields: function () { this.v$.$touch() },
    sepaInvalid: function (val) { this.paymentStore.setComplete(!val) }
  },
  mounted () {
    this.donationStore.setReference(uuid())
    this.paymentStore.setComplete(false)
    this.paymentStore.setMethods({ serialize })
    if (this.$l10n.currentLocation.formFields.confirmPaymentHolderRequired) { this.assumedPaymentHolder = false }
  }
}
</script>

<style lang="scss" scoped>
  a {
    color: $color-web-orange;
    &:visited {
      color: $color-web-orange;
    }
  }

  .confirm-payment-holder {
    margin-top: 0.5rem;
  }
</style>
