import axios from 'axios'

export default async function handleTwoFactorAuth (payment, reference, clientSecret) {
  const confirmResult = await payment.confirmTwoFactor(clientSecret, payment)
  if (confirmResult.error) {
    const errBody = {
      status: 'failed',
      details: {
        error_code: confirmResult.error.code,
        payment_intent: confirmResult.error.payment_intent.id,
        failure_message: confirmResult.error.message
      }
    }
    await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/donation/${reference}/update`, errBody)
    return Promise.resolve(errBody)
  } else {
    await axios.post(
      `${process.env.VUE_APP_BACKEND_HOST}/donation/${reference}/update`,
      { status: 'successful', details: confirmResult }
    )
    return Promise.resolve({ status: 'successful', details: confirmResult })
  }
}
