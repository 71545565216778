<template>
  <section class="campaigns">
    <div class="container contain">
      <header class="side-by-side">
        <h3>{{ $t('thankYou.campaigns.header.title') }}</h3>
        <div>
          <p>{{ $t('thankYou.campaigns.header.note') }}</p>
        </div>
      </header>
      <div class="card-deck general-shadowed tall-poppy">
        <a
          class="card"
          :href="$t('thankYou.campaigns.cards.conservation.cta_link')"
          target="_blank"
        >
          <img
            class="card-img-top image-cap"
            :src="$t('thankYou.campaigns.cards.conservation.img_src')"
          >
          <div class="card-body">
            <h4 class="card-title">{{ $t('thankYou.campaigns.cards.conservation.title') }}</h4>
            <p>{{ $t('thankYou.campaigns.cards.conservation.content') }}</p>
          </div>
        </a>
        <a
          class="card"
          :href="$t('thankYou.campaigns.cards.uncontacted.cta_link')"
          target="_blank"
        >
          <img
            class="card-img-top image-cap"
            :src="$t('thankYou.campaigns.cards.uncontacted.img_src')"
          >
          <div class="card-body">
            <h4 class="card-title">{{ $t('thankYou.campaigns.cards.uncontacted.title') }}</h4>
            <p>{{ $t('thankYou.campaigns.cards.uncontacted.content') }}</p>
          </div>
        </a>
        <a
          class="card"
          :href="$t('thankYou.campaigns.cards.tribal_voice.cta_link')"
          target="_blank"
        >
          <img
            class="card-img-top image-cap"
            :src="$t('thankYou.campaigns.cards.tribal_voice.img_src')"
          >
          <div class="card-body">
            <h4 class="card-title">{{ $t('thankYou.campaigns.cards.tribal_voice.title') }}</h4>
            <p>{{ $t('thankYou.campaigns.cards.tribal_voice.content') }}</p>
          </div>
        </a>
        <a
          class="card"
          :href="$t('thankYou.campaigns.cards.factory_schools.cta_link')"
          target="_blank"
          v-if="$tm('thankYou.campaigns.cards.factory_schools.cta_link')[0]"
        >
          <img
            class="card-img-top image-cap"
            :src="$t('thankYou.campaigns.cards.factory_schools.img_src')"
          >
          <div class="card-body">
            <h4 class="card-title">{{ $t('thankYou.campaigns.cards.factory_schools.title') }}</h4>
            <p>{{ $t('thankYou.campaigns.cards.factory_schools.content') }}</p>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
  .campaigns{
    padding: 1.5rem 0;

    @include on-non-mobile{
      padding: 3rem 0;
    }

    .card-deck{
      flex-direction: column;
      @include on-tablet(){
        flex-direction: row;
        flex-wrap: wrap;
      }
      a.card{
        @include on-tablet(){
          flex: 1 1 45%;
          margin-bottom: 1.5rem;
        }
        @include on-desktop(){
          flex: 1 1 20%;
          margin-bottom: 0rem;
        }
        &:nth-child(1){
          h4{
            color: $color-buddha-gold;
          }

          &:hover{
            background-color: $color-buddha-gold;
          }
        }
        &:nth-child(2){
          h4{
            color: $color-mai-tai;
          }

          &:hover{
            background-color: $color-mai-tai;
          }
        }
        &:nth-child(3){
          h4{
            color: $color-tall-poppy;
          }

          &:hover{
            background-color: $color-tall-poppy;
          }
        }
        &:nth-child(4){
          h4{
            color: $color-tall-poppy;
          }

          &:hover{
            background-color: $color-tall-poppy;
          }
        }
      }
    }
  }
</style>
