import storageAvailable from '@/utilities/storageAvailable'

export default function loadPaymentDetails (vueInstance, lokalStorage) {
  if (storageAvailable(lokalStorage)) {
    let storedPayment = {}
    if (lokalStorage.getItem('payment')) {
      storedPayment = JSON.parse(lokalStorage.getItem('payment'))
      vueInstance.paymentStore.setDetails(storedPayment)
      return storedPayment
    } else { return false } // raise error instead?
  }
}
