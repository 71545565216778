<template>
  <div class="contain">
    <div class="smart-debit-confirmation-page">
      <div class="page-title">
        <h2>
          <div>Survival International</div>
          <img
            class="dd-logo"
            src="https://assets.survivalinternational.org/viru/logos/direct-debit.png"
            alt="Direct debit logo"
          >
        </h2>
        <div>
          <ul class="contact-details">
            <li><strong>Address:</strong> 6 Charterhouse Buildings, London EC1M 7ET, UK</li>
            <li><strong>Tel:</strong> 020 7687 8700</li>
            <li><strong>Email: </strong><a href="mailto:info@survivalinternational.org">info@survivalinternational.org</a></li>
          </ul>
        </div>
      </div>
      <div class="confirmation-details">
        <h4>Donation details</h4>
        <div><strong>Reference:</strong> {{ reference }}</div>
        <p>
          You are donating <strong>{{ amountString }}</strong> {{ frequencyMessage }} month by <strong>Direct Debit</strong>.
        </p>
        <div class="detail">
          The details of your Direct Debit Instruction will be sent to you
          within 3 working days or no later than 10 working days before the
          first collection. If you wish, you can print this page for your
          records.
        </div>
      </div>
      <div class="direct-debit-confirmation">
        <div>
          <h4>
            Bank Details
          </h4>
          <div>
            <div><strong>Bank/building society name: </strong>{{ paymentDetails.bank }}</div>
            <div><strong>Bank/building society name branch: </strong>{{ paymentDetails.branch }}</div>
            <div v-if="this.type === 'monthly'">
              <div><strong>First Payment will be taken on: </strong>{{ this.formatDate(paymentDetails.billing_date) }}</div>
            </div>
            <div v-if="this.type === 'oneOff'">
              <div><strong>Payment will be taken on: </strong>{{ this.formatDate(paymentDetails.end_date) }}</div>
            </div>
            <div><strong>Service User Number:</strong> 388350</div>
          </div>
          <br>
          <div class="detail">
            Please pay Survival International Direct Debits from the
            account detailed in this Instruction subject to the
            safeguards assured by the Direct Debit Guarantee. I
            understand that this Instruction may remain with Survival
            International and, if so, details will be passed
            electronically to my bank/building society.
          </div>
        </div>
        <div class="confirm-cancel">
          <button
            class="cancel"
            @click="cancel('Canceled DD confirmation')"
          >
            Cancel
          </button>
          <button
            class="submit"
            @click="submit"
          >
            Confirm
          </button>
        </div>
      </div>
      <div class="direct-debit-guarantee">
        <h4>The Direct Debit Guarantee</h4>
        <ul class="detail">
          <li>
            This Guarantee is offered by all banks and building
            societies that accept instructions to pay Direct Debits
          </li>
          <li>
            If there are any changes to the amount, date or frequency
            of your Direct Debit Survival International will notify you 10
            working days in advance of your account being debited or as
            otherwise agreed. If you request Survival International to
            collect a payment, confirmation of the amount and date will be
            given to you at the time of the request.
          </li>
          <li>
            If an error is made in the payment of your Direct Debit, by
            Survival International or your bank or building society, you
            are entitled to a full and immediate refund of the amount paid
            from your bank or building society
            <br>
            If you receive a refund you are not entitled to,
            you must pay it back when Survival International asks you
            to
          </li>
          <li>
            You can cancel a Direct Debit at any time by simply
            contacting your bank or building society. Written confirmation
            may be required. Please also notify us.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { usePaymentStore } from '@/stores/payment'
import { useDonationStore } from '@/stores/donation'
import { ref } from 'vue'

export default {
  name: 'SmartDebitConfirmation',
  setup () {
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const amount = ref(null)
    const currency = ref(null)
    const reference = ref(null)
    const paymentDetails = ref(null)
    const type = ref(null)
    const amountString = ref(null)
    return {
      donationStore, paymentStore, amount , currency , reference , paymentDetails , type , amountString
    }
  },
  computed: {
    frequencyMessage () { return this.type === 'oneOff' ? 'this' : 'each' },
    thankYouPath () { return this.$t('routeNames.thankYou') }
  },
  methods: {
    submit () {
      clearInterval(this.timer)
      axios.post(
        `${process.env.VUE_APP_BACKEND_HOST}/donation/${this.reference}/update`,
        { status: 'successful', details: { status: 'Supporter confirmed Direct debit' } }
      ).then(() => this.$router.push({ name: this.thankYouPath }))
    },
    cancel (status) {
      clearInterval(this.timer)
      axios.post(
        `${process.env.VUE_APP_BACKEND_HOST}/donation/${this.reference}/update`,
        { status: 'failed', details: { status: status } }
      ).then(() => this.$router.push({ path: '/', query: { l: 'gb', payment_cancelled: true } }))
    },
    formatDate (UTCDateString) {
      return new Date(UTCDateString).toDateString()
    },
    timeOutDonation () {
      this.cancel('DD confirmation timed out')
    }
  },
  beforeMount () {
    this.paymentDetails = this.paymentStore.details.details
    this.amount = this.donationStore.amount
    this.currency = this.donationStore.currency
    this.reference = this.donationStore.reference
    this.type = this.donationStore.type
    this.amountString = this.$l10n.formatCurrency(this.amount, this.currency)
  },
  mounted () {
    this.$scrollToTop()
    this.timer = setInterval(this.timeOutDonation, 300000) // 300000ms = 5minutes
  }
}
</script>

<style lang="scss" scoped>
  .contain {
    background-color: $color-body-bg;
  }
  .smart-debit-confirmation-page {
    margin: 1rem;

    h2 {
      display: flex;
      flex-flow: row wrap;
      div {
        flex: 1 1 auto;
      }
      img {
        flex: 0 0 auto;
        margin: 1rem 0 0 0;
        @include on-non-mobile(){
          margin: 0 1rem;
        }
        max-height: 3rem;
      }
    }

    h4 {
      margin-bottom: 1rem;
    }
    .direct-debit-guarantee {
      ul {
        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .contact-details {
      a {
        text-decoration: none;
      }
    }

    .detail {
      font-size: 1rem;
    }

    .confirm-cancel {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        border: none;
        padding: 0.6em 1em;
        text-align: center;
        width: auto;
        margin: 1em;
        color: black;
        box-shadow: 2px 2px 7px rgba(black, 0.3);
        display: inline;
        max-width: 20rem;
        flex: 1 1 auto;
      }
      button.submit {
        background-color: orange;
      }
      button.cancel {
        background-color: light-grey;
      }
    }
    .dd-logo {
      width: 6rem;
    }
  }
</style>
