<template>
  <div>
    <p v-if="$t(`donationForm.donationType.prompt`)">
      <small>{{ promptText }}</small>
    </p>
    <div class="donation-type form-check-group">
      <div
        v-for="(type) in types"
        :key="type"
        class="option"
      >
        <input
          :id="`${type}-donation-type`"
          type="radio"
          v-model="donationType"
          :value="type"
        >
        <label :for="`${type}-donation-type`">
          {{ $t(`donationForm.donationType.${type}`) }}
          <DonationFrequencySelect
            v-if="type != 'oneOff'"
            v-model="donationType"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import DonationFrequencySelect from '@/components/donation/DonationFrequencySelect'
import { useRootStore } from '@/stores/root'
import { useDonationStore } from '@/stores/donation'

export default {
  name: 'DonationTypeSelect',
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    return { rootStore, donationStore }
  },
  components: { DonationFrequencySelect },
  computed: {
    hideOneOff () { return this.rootStore.omittedFrequency === 'oneOff' },
    hideRegular () { return this.rootStore.omittedFrequency === 'regular' },
    types () {
      if (this.hideOneOff) {
        return [this.regularType]
      } else if (this.hideRegular) {
        return ['oneOff']
      } else {
        return [this.regularType, 'oneOff']
      }
    },
    regularType () {
      return (this.donationType !== 'oneOff' ? this.donationType : 'monthly')
    },
    donationType: {
      get () { return this.donationStore.type },
      set (newValue) { this.donationStore.setType(newValue) }
    },
    promptText () {
      if (this.rootStore.appeal && (this.$tm(`appeals.${this.rootStore.appeal}.donationType.prompt`)[0])) {
        return this.$t(`appeals.${this.rootStore.appeal}.donationType.prompt`)
      } else {
        return this.$t(`donationForm.donationType.prompt`)
      }
    }
  },
  watch: {
    types (val) {
      if (val.length === 1) {
        const type = val[0]
        this.donationStore.setType(type)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .donation-type {
    flex-direction: row;
    margin-bottom: 1em;
    width: 100%;

    .option{
      position: relative;
      perspective: 1000px;
      transform-style: preserve-3d;
      z-index: 1000;
    }
  }
</style>
