<template>
  <div class="tax-relief">
    <div>
      <p><small v-html="$t('donationForm.additionalInfo.taxRelief.description')" /></p>
      <template v-if="showCalc">
        <p
          v-if="ineligble"
          class="relief-calc"
          v-html="$t('donationForm.additionalInfo.taxRelief.ineligible')"
        />
        <p
          v-else
          class="relief-calc"
          v-html="$t('donationForm.additionalInfo.taxRelief.calculatedTaxRelief', { reducedAmount, deduction })"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { useDonationStore } from '@/stores/donation'
import { useL10nStore } from '@/stores/l10n'
import { calculateTaxRelief } from '@/utilities/calculateTaxRelief'

export default {
  name: 'TaxReliefCalculator',
  setup () {
    const donationStore = useDonationStore()
    const l10nStore = useL10nStore()
    return { donationStore, l10nStore }
  },
  computed: {
    locationKey () { return this.$l10n.currentLocation.key },
    currentAmount () { return this.donationStore.amount },
    currentCurrency () { return this.donationStore.currency },
    currentFrequency () { return this.donationStore.type },
    taxRelief () { return calculateTaxRelief(this.currentAmount, this.currentFrequency, this.locationKey) },
    ineligble () { return this.taxRelief.ineligible },
    reducedAmount () { return this.formatCurrency(this.taxRelief.reducedAmount, this.currentCurrency) },
    deduction () { return this.formatCurrency(this.taxRelief.deduction, this.currentCurrency) },
    showCalc () { return ((this.currentCurrency === 'eur') && (this.currentAmount)) },
  },
  methods: {
    formatCurrency (value, curr) {
      return this.$l10n.formatCurrency(value, curr, { precision: 2 })
    }
  }
}
</script>

<style lang="scss" scoped>
  $text-color: #fff;

  .relief-calc {
    font-size: 1.1rem;
    background: $color-asparagus;
    color: $text-color;
    padding: 0.75rem;
    margin-top: 1rem;
  }
</style>
