<template>
  <div id="donation">
    <DonationHeader>
      <div class="columns">
        <div class="column">
          <ProgressTracker :current-step="2" />
          <DonationSummary />
          <DonationForm ref="form" />
          <p class="donation-nav">
            <GiftDonation v-if="$tm('giftDonation.display')" />
            <TaxIdentification />
            <NewsletterSignup />
            <PrivacyStatement />
            <GiftAid />
            <br>
            <router-link
              :to="{ name: 'donationIntro' }"
              class="btn btn-primary back"
            >
              <font-awesome-icon icon="arrow-left" />
              {{ $t('donationForm.back') }}
            </router-link>
            &nbsp;
            <router-link
              :to="{ name: $t('routeNames.paymentDetails') }"
              class="btn btn-primary continue"
              v-if="allowContinue"
            >
              {{ $t('donationForm.continue') }}
              <font-awesome-icon icon="arrow-right" />
            </router-link>
            <router-link
              :to="'#'"
              class="btn btn-primary disabled continue"
              @click="touchAllFields"
              v-else
            >
              {{ $t('donationForm.continue') }}
              <font-awesome-icon icon="arrow-right" />
            </router-link>
          </p>
        </div>
      </div>
    </DonationHeader>
    <DonationFooter />
  </div>
</template>

<script>
import DonationHeader from './donation/layout/DonationHeader'
import DonationFooter from './donation/layout/DonationFooter'
import DonationForm from './donation/DonationForm'
import GiftAid from '@/components/donation/GiftAid'
import TaxIdentification from '@/components/donation/TaxIdentification'
import ProgressTracker from '@/components/donation/ProgressTracker'
import DonationSummary from '@/components/donation/DonationSummary'
import GiftDonation from '@/components/donation/GiftDonation'
import NewsletterSignup from '@/components/donation/NewsletterSignup'
import PrivacyStatement from '@/components/donation/PrivacyStatement'
import { useRootStore } from '@/stores/root'

export default {
  name: 'PersonalDetails',
  setup () {
    const rootStore = useRootStore()
    return { rootStore }
  },
  components: {
    DonationFooter,
    DonationHeader,
    DonationForm,
    GiftAid,
    GiftDonation,
    TaxIdentification,
    ProgressTracker,
    DonationSummary,
    DonationSummary,
    NewsletterSignup,
    PrivacyStatement
  },
  computed: {
    allowContinue () {
      return this.rootStore.validation.form
    }
  },
  methods: {
    async touchAllFields () {
      this.rootStore.touchValidationFields(true)
      await this.$nextTick()
      this.rootStore.touchValidationFields(false)
      this.$scrollToElement('.error')
    }
  },
  mounted () {
    this.$scrollToElement('.column')
  },
  watch: {
    allowContinue: function (val) {
      if (val === true) { this.$scrollToElement('.continue') }
    }
  }
}
</script>

<style lang="scss" scoped>
  .columns {
    @include on-desktop(){
      display: flex;
      margin: 0 -0.5rem;
    }

    .column {
      margin-bottom: 1.5rem;

       @include on-desktop(){
        flex: 1;
        margin: 0;
        padding: 0.5rem 1rem;
      }
    }
  }

  .donation-nav {
    margin: 0 2rem;

    @include on-mobile(){
      margin-left: 0;
    }
  }
</style>
