<template>
  <div class="donation-progress">
    <ul>
      <li
        class="step"
        :class="{ 'current-step': currentStep === 1 }"
      >
        1
      </li>
      <li class="spacer" />
      <li
        class="step"
        :class="{ 'current-step': currentStep === 2 }"
      >
        2
      </li>
      <li class="spacer" />
      <li
        class="step"
        :class="{ 'current-step': currentStep === 3 }"
      >
        3
      </li>
      <li class="spacer" />
      <li
        class="step"
        :class="{ 'current-step': currentStep === 4 }"
      >
        <font-awesome-icon icon="check" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProgressTracker',
  props: {
    currentStep: { required: true, type: Number }
  }
}
</script>

<style lang="scss" scoped>
  div.donation-progress {
    margin: 0 2rem;
    ul {
      display: flex;
      justify-content: center;
      padding-left: 0;

      li.step {
        flex: 0 1 auto;
        list-style-type: none;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        color: $color-faded-btn-text;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
      }

      li.current-step {
        color: $color-blue-lagoon;
        background-color: white;
      }

      li.spacer {
        margin: 0.1rem 0;
        list-style-type: none;
        height : 1.2rem;
        border-bottom: 2px dotted white;
        flex-grow: 1;
      }
    }
  }
</style>
