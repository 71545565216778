function serialize (vueInstance) {
  return Promise.resolve({
    adapter: 'smartdebit',
    sort_code: vueInstance.paymentStore.details.sortCode.replace(/-|\s/g, ''),
    account_number: vueInstance.paymentStore.details.accountNumber,
    account_name: vueInstance.paymentStore.details.accountName,
    chosen_day: vueInstance.paymentStore.details.collectionDate
  })
}

function authorize (vueInstance, _result, _reference = null) {
  vueInstance.$router.push({ name: 'smartDebitConfirmation' })
}

export { serialize, authorize }
