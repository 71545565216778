<template>
  <div class="smart-debit-payment">
    <div>
      <CollectionDateSelect v-model="smartDebit.collectionDate" />
      <FormField
        :label="showAsteriskIfRequired('accountName') + $t('donationForm.details.smartDebit.accountName.label')"
        v-model="smartDebit.accountName"
        :validation="v$.smartDebit.accountName"
      />
      <FormField
        :label="showAsteriskIfRequired('accountNumber') + $t('donationForm.details.smartDebit.accountNumber.label')"
        v-model="smartDebit.accountNumber"
        :validation="v$.smartDebit.accountNumber"
        v-mask="'########'"
      />
      <FormField
        :label="showAsteriskIfRequired('sortCode') + $t('donationForm.details.smartDebit.sortCode.label')"
        v-model="smartDebit.sortCode"
        :validation="v$.smartDebit.sortCode"
        v-mask="'## ## ##'"
      />
    </div>
    <div>
      <p>
        {{ $t('donationForm.details.smartDebit.accountHolder.text-part-1') }}
      </p>
      <p>
        {{ $t('donationForm.details.smartDebit.accountHolder.text-part-2') }}
        <a
          :href="$t('donationForm.details.smartDebit.accountHolder.downloadableForm.url')"
          target="_blank"
        >
          {{ $t('donationForm.details.smartDebit.accountHolder.downloadableForm.text') }}
        </a>
      </p>
      <p>
        {{ $t('donationForm.details.smartDebit.accountHolder.directDebitGuarantee.text') }}
        <a
          :href="$t('donationForm.details.smartDebit.accountHolder.directDebitGuarantee.url')"
          target="_blank"
        >
          {{ $t('donationForm.details.smartDebit.accountHolder.directDebitGuarantee.linkText') }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import FormField from '@/components/form/FormField'
import CollectionDateSelect from '@/components/donation/CollectionDateSelect'
import { mask } from 'vue-the-mask'
import { v4 as uuid } from 'uuid'
import { authorize, serialize } from './methods'
import { required, minLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'

export default {
  name: 'SmartdebitComponent',
  components: { FormField, CollectionDateSelect },
  props: {
    validateFields: { type: Boolean, default: false }
  },
  directives: { mask },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const smartDebit = ref({ accountName: '', sortCode: '', accountNumber: '', collectionDate: '1' })
    return { donationStore, paymentStore, rootStore, v$: useVuelidate(), smartDebit }
  },
  validations: {
    smartDebit: {
      accountName: {
        required,
        minLength: minLength(3)
      },
      accountNumber: {
        required,
        minLength: minLength(8)
      },
      sortCode: {
        required,
        minLength: minLength(8) // +2 from input mask
      }
    }
  },
  methods: {
    showAsteriskIfRequired (field) {
      const fieldRequired = this.v$.smartDebit[field].required
      if (this.v$.smartDebit[field] === 'none') {
        return '* '
      }
      return (fieldRequired && fieldRequired.$invalid) ? '* ' : ''
    }
  },
  computed: {
    smartDebitInvalid () { return this.v$.smartDebit ? this.v$.smartDebit.$invalid : true },
    frequency () { return this.donationStore.type }
  },
  watch: {
    smartDebitInvalid: function (val) {
      this.paymentStore.setComplete(!val)
    },
    'smartDebit.accountName': function (_accountName) {
      this.paymentStore.setDetails({ ...this.smartDebit })
    },
    'smartDebit.accountNumber': function (_accountNumber) {
      this.paymentStore.setDetails({ ...this.smartDebit })
    },
    'smartDebit.sortCode': function (_sortCode) {
      this.paymentStore.setDetails({ ...this.smartDebit })
    },
    'smartDebit.collectionDate': function (_collectionDate) {
      this.paymentStore.setDetails({ ...this.smartDebit })
    },
    validateFields: function (_freq) { this.v$.$touch() },
    frequency: function () { this.smartDebit.collectionDate = '1' }
  },
  mounted () {
    this.donationStore.setType('monthly')
    this.rootStore.setOmittedFrequency('oneOff')
    this.donationStore.setReference(uuid().substring(0, 18))
    this.paymentStore.setComplete(false)
    this.paymentStore.setMethods({ authorize, serialize })
  },
  beforeUnmount () {
    this.rootStore.setOmittedFrequency(null)
  }
}
</script>

<style lang='scss' scoped>
  .smart-debit-payment {
    a {
      color: white;
    }
  }
</style>
