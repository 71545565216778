<template>
  <div class="wrapper">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonationContainer'
}
</script>

<style lang="scss" scoped>
  a {
    color: white;
    text-decoration: none;
  }

  .wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
  }

  .container {
    position: relative;
    z-index: 20;
    text-align: initial;
    display: inline-block;
    max-width: 45em;
    width: 100%;
    background-color: $color-blue-lagoon;
    overflow: hidden;
    padding: 1em;
    font-family: $base-font-family;

    &.full {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      background-color: rgba($color-blue-lagoon, 0.97);
      @include on-desktop(){
        max-width: none;
      }
    }
  }

</style>
