<template>
  <div class="it-tax">
    <FormField
      :label="$t('donationForm.details.taxId.idNumber')"
      v-model="taxNumber"
      :validation="v$.taxNumber"
    />
    <small>{{ $t('donationForm.details.taxId.details') }}</small>
  </div>
</template>

<script>
import FormField from '@/components/form/FormField'
import { alphaNum } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useDonationStore } from '@/stores/donation'
import { useL10nStore } from '@/stores/l10n'

export default {
  name: 'ItTaxInformation',
  components: { FormField },
  setup () {
    const donationStore = useDonationStore()
    const l10nStore = useL10nStore()
    return { v$: useVuelidate(), donationStore, l10nStore }
  },
  computed: {
    taxNumber: {
      get () { return this.donationStore.tax.taxNumber },
      set (newValue) { this.donationStore.setTax({ taxNumber: newValue }) }
    }
  },
  validations () {
    return {
      taxNumber: { alphaNum }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
