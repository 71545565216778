<template>
  <section class="galleries">
    <div class="container contain">
      <header class="side-by-side">
        <h3>{{ $t('thankYou.galleries.header.title') }}</h3>
        <div>
          <p>{{ $t('thankYou.galleries.header.note') }}</p>
        </div>
      </header>
      <div class="card-deck general-shadowed black">
        <a
          class="card"
          :href="$t('thankYou.galleries.cards.same_as_sand.cta_link')"
          target="_blank"
        >
          <img
            class="card-img-top image-cap"
            :src="$t('thankYou.galleries.cards.same_as_sand.img_src')"
          >
          <div class="card-body">
            <h4 class="card-title">{{ $t('thankYou.galleries.cards.same_as_sand.title') }}</h4>
            <p>{{ $t('thankYou.galleries.cards.same_as_sand.content') }}</p>
          </div>
        </a>
        <a
          class="card"
          :href="$t('thankYou.galleries.cards.our_universe.cta_link')"
          target="_blank"
        >
          <img
            class="card-img-top image-cap"
            :src="$t('thankYou.galleries.cards.our_universe.img_src')"
          >
          <div class="card-body">
            <h4 class="card-title">{{ $t('thankYou.galleries.cards.our_universe.title') }}</h4>
            <p v-html="$t('thankYou.galleries.cards.our_universe.content')" />
          </div>
        </a>
        <a
          class="card"
          :href="$t('thankYou.galleries.cards.great_spirits.cta_link')"
          target="_blank"
        >
          <img
            class="card-img-top image-cap"
            :src="$t('thankYou.galleries.cards.great_spirits.img_src')"
          >
          <div class="card-body">
            <h4 class="card-title">{{ $t('thankYou.galleries.cards.great_spirits.title') }}</h4>
            <p>{{ $t('thankYou.galleries.cards.great_spirits.content') }}</p>
          </div>
        </a>
      </div>
      <footer>
        <a
          class="btn btn-primary"
          :href="$t('thankYou.galleries.footer.cta_link')"
          target="_blank"
        >
          {{ $t('thankYou.galleries.footer.cta_text') }}
        </a>
      </footer>
    </div>
  </section>
</template>
<style lang="scss" scoped>
  .galleries{
    padding: 1.5rem 0;
    background-color: $color-dune;

    @include on-non-mobile{
      padding: 3rem 0;
    }

    header.side-by-side{
      color: $color-body-bg;

      h3{
        color: $color-body-bg;
      }
    }

    .card-deck{
      @include on-tablet(){
        flex-direction: column;
        .card{
          margin-bottom: 1.5rem;
        }
      }
      @include on-desktop(){
        flex-direction: row;
        .card{
          margin-bottom: 0rem;
        }
      }
    }
    footer{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0.25rem 0;
    }
  }
</style>
