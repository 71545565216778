<template>
  <div id="donation">
    <DonationHeader>
      <div class="columns">
        <div class="column">
          <CountryLocationSelect v-if="showCountryLocationSelect" />
          <TransitionSlide>
            <CurrencySelect v-if="officeLocationKnown" />
          </TransitionSlide>
          <TransitionSlide>
            <DonationTypeSelect v-if="officeLocationKnown" />
          </TransitionSlide>
          <AmountPreselect v-if="officeLocationKnown" />
          <p class="donation-nav">
            <router-link
              :to="{ name: $t('routeNames.personalDetails') }"
              class="btn btn-primary continue"
              v-if="allowContinue"
            >
              {{ $t('donationForm.continue') }}
              <font-awesome-icon icon="arrow-right" />
            </router-link>
            <router-link
              :to="'#'"
              class="btn btn-primary disabled continue"
              v-else
            >
              {{ $t('donationForm.continue') }}
              <font-awesome-icon icon="arrow-right" />
            </router-link>
          </p>
        </div>
      </div>
    </DonationHeader>
    <DonationFooter />
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import DonationHeader from './donation/layout/DonationHeader'
import DonationFooter from './donation/layout/DonationFooter'
import CurrencySelect from '@/components/donation/CurrencySelect'
import AmountPreselect from '@/components/donation/AmountPreselect'
import CountryLocationSelect from '@/components/donation/CountryLocationSelect'
import DonationTypeSelect from '@/components/donation/DonationTypeSelect'
import TransitionSlide from '@/transitions/Slide'
import { useRootStore } from '@/stores/root'
import { useDonationStore } from '@/stores/donation'

export default {
  name: 'DonationIntro',
  components: {
    TransitionSlide,
    CurrencySelect,
    DonationTypeSelect,
    AmountPreselect,
    DonationFooter,
    CountryLocationSelect,
    DonationHeader
  },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    return { rootStore, donationStore }
  },
  computed: {
    paymentCancelled () { return Object.prototype.hasOwnProperty.call(this.rootStore.queryParams, 'payment_cancelled') },
    donationDetailsGiven () {
      let queryParams = this.rootStore.queryParams
      return Object.prototype.hasOwnProperty.call(queryParams, 'amount') || Object.prototype.hasOwnProperty.call(queryParams, 'type')
    },
    showCountryLocationSelect () {
      return (this.rootStore.isEnDomain && !('l' in this.rootStore.queryParams))
    },
    currency: {
      get () { return this.donationStore.currency },
      set (newValue) { this.donationStore.setCurrency(newValue) }
    },
    country: {
      get () { return this.donationStore.country },
      set (newValue) { this.donationStore.setCountry(newValue) }
    },
    amount () { return this.donationStore.amount },
    officeLocationKnown () {
      return (this.country !== 'none') || !this.rootStore.isEnDomain || ('l' in this.rootStore.queryParams)
    },
    allowContinue () {
      if (!this.currency) { return false }
      if (this.rootStore.isEnDomain && !('l' in this.rootStore.queryParams)) {
        return (((Number(this.amount) > this.$l10n.minAmountFor(this.currency))) && (this.country !== 'none'))
      } else {
        return !!(Number(this.amount) > this.$l10n.minAmountFor(this.currency))
      }
    }
  },
  methods: {
    prefillDonation () {
      const params = this.rootStore.queryParams
      if ('type' in params && (['monthly', 'oneOff'].includes(params.type))) {
        this.donationStore.setType(params.type)
      }
      if ('amount' in params && (Number(params.amount) > 0)) {
        this.donationStore.setAmount(`${Number(params.amount)}`)
      }
      if ('currency' in params && (Object.keys(this.$l10n.availableCurrencies).includes(params.currency.toLocaleLowerCase()))) {
        this.donationStore.setCurrency(params.currency.toLocaleLowerCase())
      }
    }
  },
  mounted () {
    this.$scrollToElement('.column')
    this.$l10n.onLocationChange(
      uuid(),
      (newLocation) => {
        if (this.donationStore.currency === null) {
          this.donationStore.setCurrency(newLocation.defaultCurrency)
        }
      },
      { immediate: true }
    )
    this.donationStore.setReference(uuid())
    if (this.donationDetailsGiven) { this.prefillDonation() }
  },
  watch: {
    '$l10n.currentLocation': function (_val) {
      this.donationStore.setCurrency(this.$l10n.currentLocation.defaultCurrency)
    },
    allowContinue: function (val) {
      if (val === true) { this.$scrollToElement('.continue') }
    }
  }
}
</script>

<style lang="scss" scoped>
  .columns {
    @include on-desktop(){
      display: flex;
      margin: 0 -0.5rem;
    }

    .column {
      margin-bottom: 1.5rem;

       @include on-desktop(){
        flex: 1;
        margin: 0;
        padding: 0.5rem 1rem;
      }
    }
  }

  button.submit {
    border: none;
    padding: 0.6em 1em;
    text-align: center;
    width: 100%;
    margin-top: 1em;
    background-color: $color-web-orange;
    color: black;
    text-shadow: 0 1px 0 white;
    box-shadow: 2px 2px 7px rgba(black, 0.3);

    &.disabled {
      opacity: 0.7;
    }
  }
</style>
