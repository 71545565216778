<template>
  <div class="es-tax">
    <FormField
      :label="$t('donationForm.details.taxId.idNumber')"
      v-model="idNumber"
      :validation="v$.idNumber"
      :placeholder="$t('donationForm.details.taxId.idNumber')"
    />
    <SelectField
      :label="$t('donationForm.details.taxId.taxIdentification')"
      :list="idTypes"
      v-model="idType"
      :validation="v$.idType"
    />
    <details class="why-tax-id">
      <summary>
        <small>{{ $t('donationForm.details.taxId.whyQuestion') }}</small>
      </summary>
      <small>{{ $t('donationForm.details.taxId.whyAnswer') }}</small>
    </details>
  </div>
</template>

<script>
import SelectField from '@/components/form/SelectField'
import FormField from '@/components/form/FormField'
import { validateSpanishID, spainIdType } from '@/lib/validation/validateSpanishID'
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useDonationStore } from '@/stores/donation'
import { useL10nStore } from '@/stores/l10n'

export default {
  name: 'EsTaxInformation',
  components: { FormField, SelectField },
  setup () {
    const donationStore = useDonationStore()
    const l10nStore = useL10nStore()
    return { v$: useVuelidate(), donationStore, l10nStore }
  },
  computed: {
    idTypes () { return this.$tm('donationForm.details.taxId.idTypes') },
    currentCountry () { return this.donationStore.country },
    currentLocationKey () { return this.l10nStore.current.key },
    idType: {
      get () { return this.donationStore.tax.idType },
      set (newValue) { this.donationStore.setTax({ idType: newValue, idNumber: this.donationStore.tax.idNumber }) }
    },
    idNumber: {
      get () { return this.donationStore.tax.idNumber },
      set (newValue) { this.donationStore.setTax({ idType: this.donationStore.tax.idType, idNumber: newValue }) }
    }
  },
  validations () {
    return {
      idNumber: {
        required: requiredIf(function () { return !['none', ''].includes(this.idType) }),
        idNumber: value => ([null, ''].includes(value)) ? true : validateSpanishID(value)
      },
      idType: {
        required: requiredIf(function () { return ![null, ''].includes(this.idNumber) }),
        idType: value => validateSpanishID(this.idNumber) ? !['none', ''].includes(value) : true
      }
    }
  },
  watch: {
    idNumber: function (val) {
      if (validateSpanishID(val)) {
        this.idNumber = val.toUpperCase()
        this.idType = `${spainIdType(val)}`.toUpperCase()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .es-tax {
    :first-child {
      margin-right: 12px
    }
    .form-field {
      display: inline-block;
      width: 48.5%;
    }

    @include on-mobile() {
      .form-field {
        display: block;
        width: 100%
      }
    }
  }

  .why-tax-id {
    summary {
      small {
        &:hover {
          color: $color-web-orange;
          cursor: pointer;
        }
      }
    }
  }
</style>
