/* eslint-disable prefer-promise-reject-errors */
import awaitUpdate from '@/lib/donation/awaitUpdate'
import serialize from '@/lib/donation/serialize'
import storeDonation from '@/lib/donation/storeDonation'
import sensitiveDataFilter from '@/utilities/sensitiveDataFilter'
import axios from 'axios'

export default function submitDonation (vueInstance, lokalStorage) {
  const donationValid = vueInstance.rootStore.formValid
  if (!donationValid) {
    vueInstance.rootStore.touchValidationFields(true)
    if (document.querySelector('div.error')) {
      vueInstance.$scrollToElement('.error')
    } else {
      vueInstance.$scrollToTop()
    }
    vueInstance.$nextTick(function () {
      vueInstance.rootStore.touchValidationFields(false)
    })
    return
  }

  if (vueInstance.isSuspectedBot) {
    vueInstance.submissionPromise = Promise.resolve()
    .catch((error) => Promise.reject(error))
    .then(() => {
      return Promise.reject({ failure_message: vueInstance.$t('paymentStatus.suspectedBot') })
    })
  } else {
    storeDonation(vueInstance, lokalStorage)
    const donation = vueInstance.donationStore.donationParams
    vueInstance.submissionPromise = serialize(vueInstance)
      .then((params) => {
        const paramsWithRecaptcha = Object.assign(
          {},
          params,
          donation,
          { recaptcha_response: vueInstance.recaptchaResponse }
          )
        return axios.post(`${process.env.VUE_APP_BACKEND_HOST}/donation`, paramsWithRecaptcha)
      })
      .catch((error) => {
        const failureMessage = error.response ? error.response.data : error
        reportFailureToBugsnag(error, vueInstance)
        return Promise.reject({ failure_message: sanitisedMessage(failureMessage) })
      })
      .then(() => awaitUpdate(vueInstance, vueInstance.donationStore.reference))
      .then((result) => {
        if (result.status === 'failed') {
          reportFailureToBugsnag(result.details, vueInstance)
          return Promise.reject(sanitisedMessage(result.details))
        }
        return Promise.resolve(result.status)
    })
  }
}

function reportFailureToBugsnag (data, vueInstance) {
  const errData = JSON.stringify(sensitiveDataFilter(data))
  const error = new Error(errData)
  const reference = vueInstance.donationStore.reference
  if (reference) {
    error.name = `PaymentFailureError - Reference: ${reference}`
  } else {
    error.name = 'PaymentFailureError'
  }
  vueInstance.$bugsnag.notify(error)
}

function sanitisedMessage (data) {
  if (Object.prototype.hasOwnProperty.call(data, 'message')) {
    return data.message
  }

  return data
}
