<template>
  <TransitionFade>
    <div
      class="loading-panel-overlay"
      v-if="promise"
    >
      <div
        class="loading-panel"
        :class="className"
      >
        <div
          class="loading"
          v-if="!completed"
        >
          <slot name="loading">
            <font-awesome-icon
              icon="spinner"
              pulse
            />
            <h4>{{ $t('paymentStatus.loading') }}</h4>
          </slot>
        </div>
        <div
          class="success"
          v-if="completed && success"
        >
          <slot
            name="success"
            :result="result"
          >
            <h4>{{ result }}</h4>
          </slot>
        </div>
        <div
          class="failure"
          v-if="completed && !success"
        >
          <slot
            name="failure"
            :result="result"
          >
            <h4>{{ result }}</h4>
          </slot>
        </div>
      </div>
    </div>
  </TransitionFade>
</template>

<script>
import TransitionFade from '@/transitions/Fade'
import { ref } from 'vue'

export default {
  name: 'LoadingPanel',
  setup () {
    const completed = ref(false)
    const success = ref(null)
    const result = ref(null)
    return { completed, success, result }
  },
  components: { TransitionFade },
  props: {
    promise: { type: [Promise, null], required: true }
  },
  computed: {
    className () {
      if (!this.completed) { return 'loading' }
      return this.success ? 'success' : 'failure'
    }
  },
  emits: ['success', 'failure'],
  watch: {
    promise: {
      immediate: true,
      handler () {
        if (!this.promise) { return }
        this.completed = false
        const promise = this.promise
        this.promise
          .then(
            (result) => {
              if (this.promise !== promise) {
                return
              }
              this.completed = true
              this.success = true
              this.result = result
              this.$emit('success', result)
            },
            (result) => {
              if (this.promise !== promise) {
                return
              }
              this.completed = true
              this.success = false
              this.result = result
              this.$emit('failure', result)
            }
          )
      }
    }
  }
}
</script>

<style lang="scss">
  .loading-panel-overlay {
    background-color: rgba(black, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5000;
    text-align: center;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    .loading-panel {
      background-color: white;
      padding: 1em;
      display: inline-block;
      color: black;
      width: 40%;
      min-width: 300px;

      @include on-non-mobile(){
        min-width: 400px;
      }

      .fa-spinner {
        width: 2em;
        height: 2em;
      }

      h4 {
        margin: 0;
      }

      button {
        margin-top: 1em;
        width: 100%;
        padding: 0.5em 1em;
        border: 4px solid black;
      }
    }
  }
</style>
