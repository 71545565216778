<template>
  <nav>
    <div class="contain">
      <a :href="$t('header.homepageUrl')">
        <img
          class="logo"
          src="https://assets.survivalinternational.org/viru/layout/survival-logo.svg"
        >
      </a>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
  nav{
    background-color: #fff;
    padding: 0.5rem;

    @include on-non-mobile(){
      padding: 1rem;
    }

    .contain{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img{
      height: 40px;

      @include on-non-mobile(){
        height: 72px;
      }
    }
  }
</style>
