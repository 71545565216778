<template>
  <div class="stripe-payment">
    <div
      class="card"
      ref="card"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { verifyPublicKey, authorize, confirmTwoFactor, serialize } from './methods'
import { v4 as uuid } from 'uuid'
import { usePaymentStore } from '@/stores/payment'
import { useDonationStore } from '@/stores/donation'
import { ref } from 'vue'

export default {
  name: 'StripeComponent',
  setup () {
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const stripe = ref(null)
    const card = ref(null)
    return { donationStore, paymentStore, stripe, card }
  },
  computed: {
    cardStyles () {
      return {
        base: {
          fontSize: '20px',
          backgroundColor: 'white',
          padding: '1em'
        }
      }
    }
  },
  emits: ['set-stripe-card'],
  mounted () {
    const config = this.$l10n.paymentMethods.stripe
    this.donationStore.setReference(uuid())
    this.paymentStore.setComplete(false)
    this.$loadScript('https://js.stripe.com/v3/')
      .then(() => {
        const stripe = new Stripe(process.env[config.apiKey], { locale: config.locale })
        verifyPublicKey(stripe, config.apiKey)

        this.stripe = stripe
        const elements = stripe.elements()

        const card = elements.create('card', { hidePostalCode: true })
        this.card = card
        this.$emit('set-stripe-card', this.card)
        this.card.mount(this.$refs.card)
        this.card.on('change', ({ complete }) => { this.paymentStore.setComplete(complete) })

        const paymentMethods = {
          authorize,
          confirmTwoFactor,
          serialize,
          retrievePaymentIntent: stripe.retrievePaymentIntent,
          createToken: stripe.createToken,
          confirmCardPayment: stripe.confirmCardPayment
        }
        this.paymentStore.setMethods(paymentMethods)
      })
  }
}
</script>

<style scoped>
  .card {
    background-color: white;
    padding: 1em;
  }
</style>
