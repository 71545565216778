<template>
  <div
    id="newsletterSignup"
    v-if="display"
  >
    <h3>{{ $t('donationForm.details.newsletter.title') }}</h3>
    <div>
      <small>{{ $t('donationForm.details.newsletter.text') }}</small>
    </div>
    <CheckBox
      :label="$t('donationForm.details.newsletter.label')"
      v-model="newsletter"
    />
  </div>
</template>

<script>
import CheckBox from '@/components/form/CheckBox'
import { useDonationStore } from '@/stores/donation'

export default {
  name: 'NewsletterSignup',
  setup () {
    const donationStore = useDonationStore()
    return { donationStore }
  },
  components: { CheckBox },
  computed: {
    newsletter: {
      get () { return this.donationStore.newsletter },
      set (newValue) {
        this.donationStore.setNewsletter(newValue)
      }
    },
    display () { return this.$l10n.currentLocation.formFields.newsletter.show }
  },
  mounted () {
    this.donationStore.setNewsletter(this.$l10n.currentLocation.formFields.newsletter.prechecked)
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    color: white;
    font-family: $titles-font-family-medium;
    margin-bottom: 0.5rem;
  }

  .form-check {
    padding-top: 0.5em;
  }
</style>
