import { SPFDefaultParams, SensitiveParamFilter } from '@amaabca/sensitive-param-filter'

export default function sensitiveDataFilter (rawObject) {
  const sensitiveParams = ['forename', 'surname', 'email', 'phone', 'street', 'zip']
  const paramFilter = new SensitiveParamFilter({
    params: SPFDefaultParams.concat(sensitiveParams)
  })

  return paramFilter.filter(rawObject)
}
