<template>
  <div class="paypal-payment">
    <small>{{ $t('payment.methods.paypal.popUpWarning') }}</small>
  </div>
</template>

<script>
import { serialize } from './methods'
import { usePaymentStore } from '@/stores/payment'

export default {
  name: 'PaypalComponent',
  setup () {
    const paymentStore = usePaymentStore()
    return { paymentStore }
  },
  mounted () {
    this.paymentStore.setComplete(true)
    this.paymentStore.setMethods({ serialize })
  }
}
</script>

<style lang="scss" scoped>
</style>
