export default {
  br: 'br',
  de: 'de',
  en_gb: 'gb',
  en_us: 'us',
  en: 'us',
  gb: 'gb',
  es: 'es',
  fr: 'fr',
  it: 'it',
  pt: 'br',
  us: 'us'
}
