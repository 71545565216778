<template>
  <div
    class="donation-frequency-select"
    v-if="multipleFrequencies"
  >
    <span
      class="change"
      @click="changeClicked()"
    />
    <div
      class="dropdown"
      :class="{open: open}"
    >
      <div class="options">
        <div
          class="option"
          v-for="frequency in frequencies"
          :key="frequency"
          @click="select(frequency)"
        >
          {{ $t(`donationForm.donationType.${frequency}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDonationStore } from '@/stores/donation'
import { ref } from 'vue'

export default {
  name: 'DonationFrequencySelect',
  setup () {
    const open = ref(false)
    const donationStore = useDonationStore()
    return { donationStore, open }
  },
  computed: {
    type: {
      get () { return this.donationStore.type },
      set (newValue) { this.donationStore.setType(newValue) }
    },
    frequencies () {
      return Object.keys(this.$l10n.availableFrequencies.regular).filter(selected => selected !== this.type)
    },
    multipleFrequencies () {
      return (Object.keys(this.$l10n.availableFrequencies.regular).length > 1) && this.type !== 'oneOff'
    }
  },
  methods: {
    select (frequency) {
      this.type = frequency
      this.open = false
    },
    changeClicked () {
      this.open = !this.open
    }
  }
}
</script>

<style lang="scss" scoped>
  .donation-frequency-select{
    display: contents;
   .dropdown{
      position: absolute;
      top: 100%;
      left: -0.5rem;
      right: -0.5rem;
      z-index: -1;
      box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.35);
      transform: rotateX(-90deg);
      transition: all 250ms ease-in-out;
      transform-origin: top;

      &.open{
        display: block;
        transform: rotateX(0deg);
        z-index: 1000;
      }

      .options{
        display: block;
        background: #fff;
        padding: 0.25rem;

        .option{
          display: block;
          margin: 0.25rem;
          transition: all 150ms ease-in-out;

          @include on-non-mobile(){
            height: 3rem;
            line-height: 3rem;
          }

          &:hover{
            background: $color-web-orange;
          }
        }
      }
    }
  }

  label {
    font-weight: bold;
  }

  span.change {
    height: 100%;
    display: block;
    padding: 0rem 0.5rem;
    margin-left: 0.25rem;
    cursor: pointer;

    &:hover{
      border-color: #000;
    }

    &:after{
      border-color: #000;
      border-style: solid;
      border-width: 0.25rem 0.25rem 0 0;
      content: '';
      display: inline-block;
      height: 0.45rem;
      left: calc(50% - 0.35rem);
      position: relative;
      top: calc(50% - 0.55rem);
      vertical-align: top;
      width: 0.45rem;
      transform: rotate(135deg);
    }
  }
</style>
